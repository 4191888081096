import React, { Suspense, createContext, lazy, useEffect, useState } from 'react';
import {
  Switch,
  Route,
  withRouter
} from "react-router-dom";
import { createBrowserHistory } from 'history'
import './App.css';
import './assets/QuillEditor.css'

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import MyConstant from './config/MyConstant';
import Loader from './components/Loader';
import Landing from './pages/Landing';
import TeqitRoute from './TeqitRoute';
import Header from './components/Header';
import RegisterTeqitMe from './pages/RegisterTeqitMe';
import RegisterParentWorkspace from './pages/RegisterParentWorkspace';
const Test = lazy(() => import('./pages/Test'));
const FileRenderTest = lazy(() => import('./components/FileRenderTest'));


const {
  ZiFetchServiceClient
} = require('./protobuf/simulation/ZiFetchService_grpc_web_pb');

let simGrpcClient = new ZiFetchServiceClient(MyConstant.keyList.simulationGrpcURL, {}, {});
const {
  ZiFetchService1Client,
  ZiFetchService2Client,
  ZiFetchService3Client,
  ZiFetchService4Client,
  ZiFetchService5Client,
  ZiFetchService6Client,
  ZiFetchService7Client,
  ZiFetchService8Client,
  ZiFetchService9Client,
  ZiFetchService10Client,
  ZiPollingService1Client,
  ZiPollingService2Client,
  ZiPollingService3Client,
  ZiPollingService4Client,
  ZiPollingService5Client
} = require('./protobuf/generated/ZiFetchService_grpc_web_pb');


let grpcClient1 = new ZiFetchService1Client(MyConstant.keyList.grpcURL, {}, {});
let grpcClient2 = new ZiFetchService2Client(MyConstant.keyList.grpcURL, {}, {});
let grpcClient3 = new ZiFetchService3Client(MyConstant.keyList.grpcURL, {}, {});
let grpcClient4 = new ZiFetchService4Client(MyConstant.keyList.grpcURL, {}, {});
let grpcClient5 = new ZiFetchService5Client(MyConstant.keyList.grpcURL, {}, {});
let grpcClient6 = new ZiFetchService6Client(MyConstant.keyList.grpcURL, {}, {});
let grpcClient7 = new ZiFetchService7Client(MyConstant.keyList.grpcURL, {}, {});
let grpcClient8 = new ZiFetchService8Client(MyConstant.keyList.grpcURL, {}, {});
let grpcClient9 = new ZiFetchService9Client(MyConstant.keyList.grpcURL, {}, {});
let grpcClient10 = new ZiFetchService10Client(MyConstant.keyList.grpcURL, {}, {});

let grpcPollingClient1 = new ZiPollingService1Client(MyConstant.keyList.grpcURL, {}, {});
let grpcPollingClient2 = new ZiPollingService2Client(MyConstant.keyList.grpcURL, {}, {});
let grpcPollingClient3 = new ZiPollingService3Client(MyConstant.keyList.grpcURL, {}, {});
let grpcPollingClient4 = new ZiPollingService4Client(MyConstant.keyList.grpcURL, {}, {});
let grpcPollingClient5 = new ZiPollingService5Client(MyConstant.keyList.grpcURL, {}, {});

const reportProto = require('./protobuf/report/ZiFetchService_grpc_web_pb');
let reportGrpcClient1 = new reportProto.ZiFetchService1Client(MyConstant.keyList.reportGrpcURL, {}, {});
let reportGrpcClient2 = new reportProto.ZiFetchService2Client(MyConstant.keyList.reportGrpcURL, {}, {});
let reportGrpcClient3 = new reportProto.ZiFetchService3Client(MyConstant.keyList.reportGrpcURL, {}, {});
let reportGrpcClient4 = new reportProto.ZiFetchService4Client(MyConstant.keyList.reportGrpcURL, {}, {});
let reportGrpcClient5 = new reportProto.ZiFetchService5Client(MyConstant.keyList.reportGrpcURL, {}, {});
let reportGrpcClient6 = new reportProto.ZiFetchService6Client(MyConstant.keyList.reportGrpcURL, {}, {});
let reportGrpcClient7 = new reportProto.ZiFetchService7Client(MyConstant.keyList.reportGrpcURL, {}, {});
let reportGrpcClient8 = new reportProto.ZiFetchService8Client(MyConstant.keyList.reportGrpcURL, {}, {});
let reportGrpcClient9 = new reportProto.ZiFetchService9Client(MyConstant.keyList.reportGrpcURL, {}, {});
let reportGrpcClient10 = new reportProto.ZiFetchService10Client(MyConstant.keyList.reportGrpcURL, {}, {});


const searchProto = require('./protobuf/search/ZiFetchService_grpc_web_pb');
let searchGrpcClient1 = new searchProto.ZiFetchService1Client(MyConstant.keyList.searchGrpcURL, {}, {});
let searchGrpcClient2 = new searchProto.ZiFetchService2Client(MyConstant.keyList.searchGrpcURL, {}, {});
let searchGrpcClient3 = new searchProto.ZiFetchService3Client(MyConstant.keyList.searchGrpcURL, {}, {});
let searchGrpcClient4 = new searchProto.ZiFetchService4Client(MyConstant.keyList.searchGrpcURL, {}, {});
let searchGrpcClient5 = new searchProto.ZiFetchService5Client(MyConstant.keyList.searchGrpcURL, {}, {});
let searchGrpcClient6 = new searchProto.ZiFetchService6Client(MyConstant.keyList.searchGrpcURL, {}, {});
let searchGrpcClient7 = new searchProto.ZiFetchService7Client(MyConstant.keyList.searchGrpcURL, {}, {});
let searchGrpcClient8 = new searchProto.ZiFetchService8Client(MyConstant.keyList.searchGrpcURL, {}, {});
let searchGrpcClient9 = new searchProto.ZiFetchService9Client(MyConstant.keyList.searchGrpcURL, {}, {});
let searchGrpcClient10 = new searchProto.ZiFetchService10Client(MyConstant.keyList.searchGrpcURL, {}, {});

const docsProto = require('./protobuf/docs/generated/ZiFetchService_grpc_web_pb');
let docsGrpcClient1 = new docsProto.ZiFetchService1Client(MyConstant.keyList.docsGrpcURL, {}, {});
let docsGrpcClient2 = new docsProto.ZiFetchService2Client(MyConstant.keyList.docsGrpcURL, {}, {});
let docsGrpcClient3 = new docsProto.ZiFetchService3Client(MyConstant.keyList.docsGrpcURL, {}, {});
let docsGrpcClient4 = new docsProto.ZiFetchService4Client(MyConstant.keyList.docsGrpcURL, {}, {});
let docsGrpcClient5 = new docsProto.ZiFetchService5Client(MyConstant.keyList.docsGrpcURL, {}, {});
let docsGrpcClient6 = new docsProto.ZiFetchService6Client(MyConstant.keyList.docsGrpcURL, {}, {});
let docsGrpcClient7 = new docsProto.ZiFetchService7Client(MyConstant.keyList.docsGrpcURL, {}, {});
let docsGrpcClient8 = new docsProto.ZiFetchService8Client(MyConstant.keyList.docsGrpcURL, {}, {});
let docsGrpcClient9 = new docsProto.ZiFetchService9Client(MyConstant.keyList.docsGrpcURL, {}, {});
let docsGrpcClient10 = new docsProto.ZiFetchService10Client(MyConstant.keyList.docsGrpcURL, {}, {});

let docsGrpcPollingClient1 = new docsProto.ZiPollingService1Client(MyConstant.keyList.docsGrpcURL, {}, {});
let docsGrpcPollingClient2 = new docsProto.ZiPollingService2Client(MyConstant.keyList.docsGrpcURL, {}, {});
let docsGrpcPollingClient3 = new docsProto.ZiPollingService3Client(MyConstant.keyList.docsGrpcURL, {}, {});
let docsGrpcPollingClient4 = new docsProto.ZiPollingService4Client(MyConstant.keyList.docsGrpcURL, {}, {});
let docsGrpcPollingClient5 = new docsProto.ZiPollingService5Client(MyConstant.keyList.docsGrpcURL, {}, {});


const appBuilderProto = require('./protobuf/appBuilder/ZiFetchService_grpc_web_pb');
let appBuilderGrpcClient1 = new appBuilderProto.ZiFetchService1Client(MyConstant.keyList.appBuilderGrpcURL, {}, {});
let appBuilderGrpcClient2 = new appBuilderProto.ZiFetchService2Client(MyConstant.keyList.appBuilderGrpcURL, {}, {});
let appBuilderGrpcClient3 = new appBuilderProto.ZiFetchService3Client(MyConstant.keyList.appBuilderGrpcURL, {}, {});
let appBuilderGrpcClient4 = new appBuilderProto.ZiFetchService4Client(MyConstant.keyList.appBuilderGrpcURL, {}, {});
let appBuilderGrpcClient5 = new appBuilderProto.ZiFetchService5Client(MyConstant.keyList.appBuilderGrpcURL, {}, {});
let appBuilderGrpcClient6 = new appBuilderProto.ZiFetchService6Client(MyConstant.keyList.appBuilderGrpcURL, {}, {});
let appBuilderGrpcClient7 = new appBuilderProto.ZiFetchService7Client(MyConstant.keyList.appBuilderGrpcURL, {}, {});
let appBuilderGrpcClient8 = new appBuilderProto.ZiFetchService8Client(MyConstant.keyList.appBuilderGrpcURL, {}, {});
let appBuilderGrpcClient9 = new appBuilderProto.ZiFetchService9Client(MyConstant.keyList.appBuilderGrpcURL, {}, {});
let appBuilderGrpcClient10 = new appBuilderProto.ZiFetchService10Client(MyConstant.keyList.appBuilderGrpcURL, {}, {});

const history = createBrowserHistory();

const grpcClient = new Array(grpcClient1, grpcClient2, grpcClient3, grpcClient4, grpcClient5, grpcClient6, grpcClient7, grpcClient8, grpcClient9, grpcClient10);
const grpcPollingClient = new Array(grpcPollingClient1, grpcPollingClient2, grpcPollingClient3, grpcPollingClient4, grpcPollingClient5)
const searchGrpcClient = new Array(searchGrpcClient1, searchGrpcClient2, searchGrpcClient3, searchGrpcClient4, searchGrpcClient5, searchGrpcClient6, searchGrpcClient7, searchGrpcClient8, searchGrpcClient9, searchGrpcClient10);
const docsGrpcClient = new Array(docsGrpcClient1, docsGrpcClient2, docsGrpcClient3, docsGrpcClient4, docsGrpcClient5, docsGrpcClient6, docsGrpcClient7, docsGrpcClient8, docsGrpcClient9, docsGrpcClient10);
const docsGrpcPollingClient = new Array(docsGrpcPollingClient1, docsGrpcPollingClient2, docsGrpcPollingClient3, docsGrpcPollingClient4, docsGrpcPollingClient5);

const reportGrpcClient = new Array(reportGrpcClient1, reportGrpcClient2, reportGrpcClient3, reportGrpcClient4, reportGrpcClient5, reportGrpcClient6, reportGrpcClient7, reportGrpcClient8, reportGrpcClient9, reportGrpcClient10);
const appBuilderGrpcClient = new Array(appBuilderGrpcClient1, appBuilderGrpcClient2, appBuilderGrpcClient3, appBuilderGrpcClient4, appBuilderGrpcClient5, appBuilderGrpcClient6, appBuilderGrpcClient7, appBuilderGrpcClient8, appBuilderGrpcClient9, appBuilderGrpcClient10);

const {
  ZiFetchServiceClient: UmedmeZiFetchServiceClient,
} = require('./protobuf/umedme/ZiFetchService_grpc_web_pb');
let umedmegrpcClient = new UmedmeZiFetchServiceClient(MyConstant.keyList.umedmeGrpcURL, {}, {});
export const UmedmeGrpcContext = createContext(umedmegrpcClient);

export const AppBuilderGrpcContext = createContext(appBuilderGrpcClient);

function App(props) {
  const [headerView, setHeaderView] = useState(false);

  useEffect(() => {
    let url = props.location.pathname;
    if (url === "/" || url === "/register" || url.includes("/create-c-workspace")) {
      setHeaderView(true)
    } else {
      setHeaderView(false)
    }
  }, [props.location.pathname])


  return <div className='teqit-container'>
    <ToastContainer />
    {headerView && <Header />}
    <div className="teqit-container-body" style={{ marginTop: headerView ? 75 : 0 }}>
      <Suspense fallback={<Loader />}>
        {
          <Switch>
            {/* <Route path="*">
              <UnderMaintenance />
            </Route> */}
            <Route exact path={"/"} >
              <Landing grpcClient={grpcClient} history={history} />
            </Route>
            <Route exact path={"/register"} >
              <RegisterTeqitMe grpcClient={grpcClient} history={history} />
            </Route>
            <Route exact path={"/file-test"} >
              <FileRenderTest action={"chat"} fileKey={"big_buck_bunny_240p_20mb.mp4"} id={"8cb5a109ea274bf5afac1de6d28ab6c4"} />
            </Route>
            <Route exact path={"/test-transliteration"} >
              <Test grpcClient={grpcClient} history={history} />
            </Route>
            <Route exact path={"/create-c-workspace/:parentOrgId"} >
              <RegisterParentWorkspace umedmegrpcClient={umedmegrpcClient} grpcClient={grpcClient} history={history} />
            </Route>
            <Route path={MyConstant.keyList.projectUrl + "/"}>
              <TeqitRoute appBuilderGrpcClient={appBuilderGrpcClient} simGrpcClient={simGrpcClient} docsGrpcPollingClient={docsGrpcPollingClient} docsGrpcClient={docsGrpcClient} searchGrpcClient={searchGrpcClient} reportGrpcClient={reportGrpcClient} grpcClient={grpcClient} grpcPollingClient={grpcPollingClient} history={history} />
            </Route>
            {/* <Route path="*">
              <NoMatch />
            </Route> */}
          </Switch>
        }
      </Suspense>
    </div>
  </div>
}

export default withRouter(App);
