import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import CardToggle from '../../../components/CardToggle';

const FunctionCaptureForm = (props) => {
    let { aiAgentFlow } = props;
    const [functionName, setFunctionName] = useState('');
    const [description, setDescription] = useState('');
    const [prompt, setPrompt] = useState('');
    const [typeOfSource, setTypeOfSource] = useState('HTTP');
    const [httpFields, setHttpFields] = useState({ method: 'GET', apiURL: '', authorization: '' });
    const [sqliteFields, setSqliteFields] = useState({ dbPath: '', userName: '', password: '' });

    const [args, setArgs] = useState([{ name: '', type: 'string', description: '', required: false }]);
    const [errors, setErrors] = useState({});
    const [validate, setValidate] = useState(false);
    const [toggle, setToggle] = useState(false);

    useEffect(() => {
        if(Object.keys(aiAgentFlow).length > 0) {
            console.log("Test", aiAgentFlow)
            let { typeOfSource, prompt, http, sqlite, toolCall } = aiAgentFlow
            setTypeOfSource(typeOfSource)
            setPrompt(prompt)
            setHttpFields(http)
            setSqliteFields(sqlite)

            let { name, description } = toolCall.function
            setFunctionName(name)
            setDescription(description)

            let args = []
            let properties = toolCall.function.parameters.properties
            let required = toolCall.function.parameters.required
            for (let key in properties) {
                let { description } = properties[key]
                args.push({ name: key, type: 'string', description, required: required.includes(key) })
            }
            setArgs(args)
        }
    }, [])

    useEffect(() => {
        updateValue()
        setValidate(false)
    }, [prompt, functionName, description, args, typeOfSource, httpFields, sqliteFields])

    const handleArgsChange = (index, event) => {
        const newArgs = [...args];
        newArgs[index][event.target.name] = event.target.value;
        setArgs(newArgs);
    };

    const addArg = () => {
        setArgs([...args, { name: '', type: 'string', description: '', required: false }]);
    };

    const removeArg = (index) => {
        const newArgs = args.filter((_, i) => i !== index);
        setArgs(newArgs);
    };


    const validateForm = () => {
        const newErrors = {};
        const nameRegex = /^[a-z_]+$/; // Regex for lowercase letters and underscores

        if (!functionName || !nameRegex.test(functionName)) {
            newErrors.functionName = 'Function name must be lowercase letters and underscores only';
        }

        if (!description) newErrors.description = 'Description is required';
        if (!prompt) newErrors.prompt = 'Prompt Template is required';

        args.forEach((arg, index) => {
            if (!arg.name || !nameRegex.test(arg.name)) {
                newErrors[`argName${index}`] = 'Parameter name must be lowercase letters and underscores only';
            }
            if (!arg.type) newErrors[`argType${index}`] = 'Parameter type is required';
            if (!arg.description) newErrors[`argDesc${index}`] = 'Parameter description is required';
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!validateForm()) return;

        toast.success("Validated successfully.", {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setValidate(true)
        updateValue()
    };

    function updateValue() {
        const finalOutput = {
            toolCall: {
                type: "function",
                function: {
                    name: functionName,
                    description: description,
                    parameters: {
                        type: 'object',
                        properties: args.reduce((acc, arg) => {
                            acc[arg.name] = {
                                type: "string",
                                description: arg.description
                            };
                            return acc;
                        }, {}),
                        required: args.filter(arg => arg.required).map(arg => arg.name)
                    }
                }
            },
            typeOfSource, 
            prompt,
            http: httpFields, 
            sqlite: sqliteFields
        };
        props.setAiAgentFlow(finalOutput)
    }

    return <React.Fragment>
        <CardToggle
            title={"AI Agent Setup"}
            bodyClassName={"p-0"}
            toggleMenuOpen={toggle} toggleOnchange={() => {
                setToggle(!toggle)
            }} >

            <form onSubmit={handleSubmit} className="ai-task-block p-2">
                <div className='form-group'>
                    <label className='custom'>Type of Source</label>
                    <div>
                        <div className="form-check form-check-inline">
                            <input
                                type="radio"
                                name="typeOfSource"
                                className="form-check-input"
                                value="HTTP"
                                checked={typeOfSource === 'HTTP'}
                                onChange={(e) => setTypeOfSource(e.target.value)}
                            />
                            <label className="form-check-label">REST</label>
                        </div>
                        {/* <div className="form-check form-check-inline">
                        <input
                            type="radio"
                            name="typeOfSource"
                            className="form-check-input"
                            value="SQLite"
                            checked={typeOfSource === 'SQLite'}
                            onChange={(e) => setTypeOfSource(e.target.value)}
                        />
                        <label className="form-check-label">SQLite</label>
                    </div> */}
                    </div>
                </div>

                {typeOfSource === 'HTTP' && (
                    <div className='row'>
                        <div className='col-sm-4'>
                            <div className='form-group'>
                                <label className="form-label custom">Method</label>
                                <select
                                    className="form-control"
                                    value={httpFields.method}
                                    onChange={(e) => setHttpFields({ ...httpFields, method: e.target.value })}
                                >
                                    <option value="GET">GET</option>
                                    <option value="POST">POST</option>
                                </select>
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div className='form-group'>
                                <label className="form-label custom">API URL</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={httpFields.apiURL}
                                    onChange={(e) => setHttpFields({ ...httpFields, apiURL: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div className='form-group'>
                                <label className="form-label custom">Authorization</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={httpFields.authorization}
                                    onChange={(e) => setHttpFields({ ...httpFields, authorization: e.target.value })}
                                />
                            </div>
                        </div>
                    </div>
                )}

                {typeOfSource === 'SQLite' && (
                    <div className='row'>
                        <div className='col-sm-4'>
                            <div className='form-group'>
                                <label className="form-label custom">Database Path</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={sqliteFields.dbPath}
                                    onChange={(e) => setSqliteFields({ ...sqliteFields, dbPath: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div className='form-group'>
                                <label className="form-label custom">Username</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={sqliteFields.userName}
                                    onChange={(e) => setSqliteFields({ ...sqliteFields, userName: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div className='form-group'>
                                <label className="form-label custom">Password</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    value={sqliteFields.password}
                                    onChange={(e) => setSqliteFields({ ...sqliteFields, password: e.target.value })}
                                />
                            </div>
                        </div>
                    </div>
                )}
                <div className='row'>
                    <div className='col-sm-4'>
                        <div className="form-group">
                            <label className="form-label custom">Agent Name</label>
                            <input
                                type="text"
                                className={`form-control ${errors.functionName ? 'is-invalid' : ''}`}
                                value={functionName}
                                onChange={(e) => setFunctionName(e.target.value)}
                            />
                            {errors.functionName && <div className="invalid-feedback">{errors.functionName}</div>}
                        </div>
                    </div>
                    <div className='col-sm-4'>
                        <div className="form-group">
                            <label className="form-label custom">Description</label>
                            <textarea
                                rows="1"
                                className={`form-control ${errors.description ? 'is-invalid' : ''}`}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                            {errors.description && <div className="invalid-feedback">{errors.description}</div>}
                        </div>
                    </div>
                    <div className='col-sm-4'>
                        <div className="form-group">
                            <label className="form-label custom">Prompt Template</label>
                            <textarea
                                rows="1"
                                className={`form-control ${errors.prompt ? 'is-invalid' : ''}`}
                                value={prompt}
                                onChange={(e) => setPrompt(e.target.value)}
                            />
                            {errors.prompt && <div className="invalid-feedback">{errors.prompt}</div>}
                        </div>
                    </div>
                </div>
                <label className='custom'>Parameters</label>
                {args.map((arg, index) => (
                    <div key={index} className="row mb-3">
                        <div className="col-sm-4">
                            <input
                                type="text"
                                name="name"
                                placeholder="Name"
                                className={`form-control ${errors[`argName${index}`] ? 'is-invalid' : ''}`}
                                value={arg.name}
                                onChange={(e) => handleArgsChange(index, e)}
                            />
                            {errors[`argName${index}`] && <div className="invalid-feedback">{errors[`argName${index}`]}</div>}
                        </div>
                        {/* <div className="col">
                        <input
                            type="text"
                            name="type"
                            placeholder="Type"
                            className={`form-control ${errors[`argType${index}`] ? 'is-invalid' : ''}`}
                            value={arg.type}
                            onChange={(e) => handleArgsChange(index, e)}
                        />
                        {errors[`argType${index}`] && <div className="invalid-feedback">{errors[`argType${index}`]}</div>}
                    </div> */}
                        <div className="col-sm-4">
                            <textarea
                                rows="1"
                                name="description"
                                placeholder="Description"
                                className={`form-control ${errors[`argDesc${index}`] ? 'is-invalid' : ''}`}
                                value={arg.description}
                                onChange={(e) => handleArgsChange(index, e)}
                            />
                            {errors[`argDesc${index}`] && <div className="invalid-feedback">{errors[`argDesc${index}`]}</div>}
                        </div>
                        <div className="col-auto d-flex align-items-center">
                            <div className="form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={arg.required}
                                    onChange={(e) => {
                                        const newArgs = [...args];
                                        newArgs[index].required = e.target.checked;
                                        setArgs(newArgs);
                                    }}
                                />
                                <label className="form-check-label">Required</label>
                            </div>
                        </div>
                        <div className="col-auto">
                            <button type="button" className="btn btn-sm btn-danger" onClick={() => removeArg(index)}>
                                Remove
                            </button>
                        </div>
                    </div>
                ))}

                <div className='text-right'>
                    <button type="button" className="btn btn-sm btn-secondary" onClick={addArg}>
                        Add Parameter
                    </button>
                </div>
                {
                    !validate && <div className='text-center mt-3'>
                        <button type="submit" className="btn btn-primary">
                            Validate
                        </button>
                    </div>
                }

            </form>
        </CardToggle>
    </React.Fragment>
};

export default FunctionCaptureForm;
