import React, { useContext, useEffect, useState } from "react";
import { capitalize, coversionED, formatAMPM, formatUTCAMPM, getAppInfo, getDateWithFormat, getGrpcClient, getHoursAndMinutes, getOnlyDateWithFormat, getOnlyUTCDateWithFormat, getUuidv4, isJson } from "../config/Common";
import Modal from "./Modal";
import CompTabs from "./CompTabs";
import { ZiFetchRequest } from "../protobuf/generated/ZiFetchService_grpc_web_pb";
import GlobalDomainCons from "../config/GlobalDomainCons";
import GlobalEventCons from "../config/GlobalEventCons";
import GlobalMsgCons from "../config/GlobalMsgCons";
import { useSelector } from "react-redux";
import Loader from "./Loader";
import CardToggle from "./CardToggle";
import { AppBuilderGrpcContext } from "../App";
import { toast } from 'react-toastify';

export default function AgentResponse(props) {
    const appBuilderGrpcClient = useContext(AppBuilderGrpcContext);

    let { agentRespone, chatMessage, channelId, openApps, formatString, bookingDisabled } = props;
    let { functionResponse, toolsCallResponse, designType, projectId } = agentRespone
    let [reviewData, setReviewData] = useState(null)
    let [activeTab, setActiveTab] = useState("preferred-search-result");
    let [sortBy, setSortBy] = useState("time_to_travel");
    let [explanationLoader, setExplanationLoader] = useState(false);
    let [flightExpand, setFlightExpand] = useState(true);
    let [carExpand, setCarExpand] = useState(true);
    let [hotelExpand, setHotelExpand] = useState(true);
    let [selectedFlight, setSelectedFlight] = useState({});
    let [selectedCar, setSelectedCar] = useState({});
    let [selectedHotel, setSelectedHotel] = useState({});

    const auth = useSelector(state => state.guestAuthReducer);

    useEffect(() => {
        if (reviewData) {
            setActiveTab("preferred-search-result")
            if (!reviewData.explanation && channelId) {
                updateExplanation()
                setExplanationLoader(true)
            }
        }
    }, [reviewData])

    function confirmTrip() {
        let data = {
            flight: selectedFlight,
            car: Object.keys(selectedCar).length > 0 ? selectedCar : null,
            hotel: Object.keys(selectedHotel).length > 0 ? selectedHotel : null,
            status: "booked",
            booking_info: {
                ticket_number: "",
                seat_no: "",
                from: "",
                to: "",
                date_of_journey: new Date().toISOString()
            },
            booked_at: new Date().toISOString(),
            canceled_at: ""
        }

        let postJson = {
            reqId: getUuidv4(),
            userId: auth.loginId,
            orgId: auth.activeCompany,
            emailId: auth.loggedEmail,
            dataType: "trip",
            data: JSON.stringify(data)
        }

        console.log("Test", postJson)
        const request = new ZiFetchRequest();
        request.setDomain("journey");
        request.setEvent("addJourneyData");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                if (responseData.response === GlobalMsgCons.success) {
                    toast.success("Booked Successfully.", {
                        position: "top-center",
                        autoClose: GlobalMsgCons.autoClose,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }
        });
    }

    function getResponse() {
        let { tool_calls } = toolsCallResponse.message
        let { name, arguments: resArguments } = tool_calls[0].function
        let message = null;
        if (name === "make_flight_ticket_booking" || name === "book_flight" || name === "search_flight") {
            let { arrival, date_of_journey, departure, date_of_journey_start_time } = resArguments
            const dateTimeString = `${date_of_journey}T${date_of_journey_start_time ? getHoursAndMinutes(date_of_journey_start_time) : "00"}:00`;
            // const date = new Date(dateTimeString);

            let result = JSON.parse(functionResponse).result
            let availableFlights = []
            if (result) {
                result = result ? result[0] : ""
                let data = result.substring(1, result.length - 1);
                availableFlights = JSON.parse(data);
            }

            message = <div className="book-now-block flight-details-box">
                <div className="p-2">
                    I found these results. To make a booking, please click the button below.
                </div>
                <div className="d-flex ticket-box p-2">
                    <div className="ticket-label">
                        <b>From</b>
                    </div>
                    <div>
                        : {departure}
                    </div>
                </div>
                <div className="d-flex ticket-box p-2">
                    <div className="ticket-label">
                        <b>To</b>
                    </div>
                    <div>
                        : {arrival}
                    </div>
                </div>
                <div className="d-flex ticket-box p-2">
                    <div className="ticket-label">
                        <b>Date</b>
                    </div>
                    <div>
                        : {dateTimeString}
                    </div>
                </div>
                {
                    name === "search_flight" && <div>
                        {
                            availableFlights.map((item, index) => {
                                let { airline, ticket_price, departure_time, arrival_time, flight_duration } = item
                                let departure = new Date(departure_time)
                                let arrival = new Date(arrival_time)
                                return <article className="row mx-0" key={index} style={{ borderBottom: "1px dashed #ddd" }}>
                                    <div className="col">
                                        <b>{airline}</b>
                                    </div>
                                    <div className="col">
                                        <time className="flight-card__time">
                                            {formatAMPM(departure) + " - " + formatAMPM(arrival)}
                                        </time>
                                        <p className="flight-card__duration">{flight_duration}</p>
                                    </div>
                                    <div className="col">
                                        {
                                            Object.keys(ticket_price).map((item, index) => {
                                                let obj = ticket_price[item]
                                                let { single } = obj
                                                return <div value={item} key={index}>{capitalize(item.replaceAll("_", " ")) + " - " + single}</div>
                                            })
                                        }
                                    </div>
                                </article>
                            })
                        }
                    </div>
                }
                <div className="m-2">
                    <button style={{ backgroundColor: "#1777eb", color: "#fff" }} className="btn btn-block" onClick={() => {
                        let details = {
                            "title": "Travel Services",
                            "description": "",
                            "appsUrl": "https://teqbahn.com/builder-web/auth/makemyjourney",
                            "color": "#1e325c",
                            "logo": "",
                            "id": chatMessage.id,
                            "iframeUrl": "https://teqbahn.com/builder-web/auth/makemyjourney",
                            "appsType": "other",
                            "appsSubType": "all",
                            "accessType": "public",
                            "isAppBuilder": true,
                            "isEditable": true,
                            "featuresAvailable": [],
                            "channelId": channelId,
                            "messageId": chatMessage.id,
                        }
                        openApps(details.id, details)
                    }} disabled={bookingDisabled}>Book Now</button>
                </div>
            </div>
        } else if (name === "search_ticket" || name === "book_car") {
            let { ticket_number } = resArguments
            let response = JSON.parse(functionResponse).result
            if (response && response.booking_info) {
                let { flight, car, hotel, booking_info, status } = response
                let { flight_number, airline, departure_time, arrival_time } = flight
                let { seat_no, from, to } = booking_info
                let departure = new Date(departure_time)
                let arrival = new Date(arrival_time)

                let carElement;
                if (car) {
                    let { company, car_type, car_model, insurance_amount, price_per_day, start_date, end_date } = car

                    carElement = <React.Fragment>
                        <div className="d-flex py-2" style={{ borderTop: "1px dashed #4648a1" }}>
                            <div className="flex-1">
                                <div>
                                    <b>Car</b>
                                    <div>
                                        {company} <br />
                                        {car_model} <br />
                                        {car_type}
                                    </div>
                                </div>

                            </div>
                            <div className="flex-1">
                                <b>Date</b>
                                <div>{getDateWithFormat(new Date(start_date).getTime())} - {getDateWithFormat(new Date(end_date).getTime())}</div>
                            </div>
                            <div className="flex-1">
                                <div>
                                    <b>Price Per Day</b>
                                    <div>{price_per_day}</div>
                                </div>
                                <div>
                                    <b>Insurance</b>
                                    <div>{insurance_amount}</div>
                                </div>
                            </div>
                        </div>
                        {
                            name === "book_car" && <div className="m-2">
                                <button style={{ backgroundColor: "#1777eb", color: "#fff" }} className="btn btn-block" onClick={() => {
                                    let details = {
                                        "title": "Travel Services",
                                        "description": "",
                                        "appsUrl": "https://teqbahn.com/builder-web/auth/makemyjourney",
                                        "color": "#1e325c",
                                        "logo": "",
                                        "id": chatMessage.id,
                                        "iframeUrl": "https://teqbahn.com/builder-web/auth/makemyjourney",
                                        "appsType": "other",
                                        "appsSubType": "all",
                                        "accessType": "public",
                                        "isAppBuilder": true,
                                        "isEditable": true,
                                        "featuresAvailable": [],
                                        "channelId": channelId,
                                        "messageId": chatMessage.id,
                                    }
                                    openApps(details.id, details)
                                }} disabled={bookingDisabled}>Update Car Booking</button>
                            </div>
                        }
                    </React.Fragment>
                } else if (name === "book_car") {
                    carElement = <div className="m-2">
                        <button style={{ backgroundColor: "#1777eb", color: "#fff" }} className="btn btn-block" onClick={() => {
                            let details = {
                                "title": "Travel Services",
                                "description": "",
                                "appsUrl": "https://teqbahn.com/builder-web/auth/makemyjourney",
                                "color": "#1e325c",
                                "logo": "",
                                "id": chatMessage.id,
                                "iframeUrl": "https://teqbahn.com/builder-web/auth/makemyjourney",
                                "appsType": "other",
                                "appsSubType": "all",
                                "accessType": "public",
                                "isAppBuilder": true,
                                "isEditable": true,
                                "featuresAvailable": [],
                                "channelId": channelId,
                                "messageId": chatMessage.id,
                            }
                            openApps(details.id, details)
                        }} disabled={bookingDisabled}>Book Now</button>
                    </div>
                }

                let hotelElement;
                if (hotel) {
                    let { name, location, amount, start_date, end_date, numberOfBeds, typeOfBed, bookedRoom } = hotel

                    hotelElement = <div className="d-flex py-2" style={{ borderTop: "1px dashed #4648a1" }}>
                        <div className="flex-1">
                            <div>
                                <b>Room : </b> {bookedRoom}
                            </div>
                            <div>
                                <b>Hotel : </b>
                                <div>
                                    {name} <br />
                                    {location} <br />
                                </div>
                            </div>
                        </div>
                        <div className="flex-1 px-2">
                            <b>Date</b>
                            <div>{getDateWithFormat(new Date(start_date).getTime())} - </div>
                            <div>{getDateWithFormat(new Date(end_date).getTime())}</div>

                        </div>
                        <div className="flex-1">
                            <div>
                                <b>Number Of Beds : </b> {numberOfBeds}
                            </div>
                            <div>
                                <b>Type Of Bed : </b> <span className="text-capitalize">{typeOfBed}</span>
                            </div>
                            <div>
                                <b>Price Per Day : </b> {amount}
                            </div>

                        </div>
                    </div>
                }

                message = <div className="book-now-block flight-details-box">
                    <main className="ticket-box p-3">
                        <header className="d-flex">
                            <div className="flex-1">
                                <h4>{ticket_number ? ticket_number : "Ticket Number"}</h4>
                                <h5>Seat No: {seat_no ? seat_no : "SN"}</h5>
                            </div>
                            <div className="mx-2">
                                <b>Flight</b>
                                <p>
                                    {airline} <br />
                                    {flight_number}
                                </p>
                            </div>
                            <div className="mx-5">
                                <b>Status</b>
                                <div>
                                    <div className="badge badge-success text-capitalize">{status}</div>
                                </div>
                            </div>
                        </header>
                        <div className="d-flex py-2" style={{ borderTop: "1px dashed #4648a1" }}>
                            <section className="flight--general flex-1">
                                <div>
                                    <b className="text-capitalize">{from}</b>
                                </div>
                                <div>
                                    to
                                </div>
                                <div>
                                    <b className="text-capitalize">{to}</b>
                                </div>
                            </section>
                            <section className="flight--TimeInfo flex-1">
                                <div className="d-flex">
                                    <div className="flex-1 pr-2">
                                        <b>Date</b>
                                        <p>{getOnlyDateWithFormat(departure)}</p>
                                    </div>
                                    <div className="flex-1 pr-2">
                                        <b>Departure</b>
                                        <p>{formatAMPM(departure)}</p>
                                    </div>
                                    <div className="flex-1">
                                        <b>Arrival</b>
                                        <p>{formatAMPM(arrival)}</p>
                                    </div>
                                </div>
                                <div style={{ border: "1px dashed #4648a1" }}>
                                    <div className="text-center">
                                        <b>UTC</b>
                                    </div>
                                    <div className="d-flex">
                                        <div className="flex-1 pr-2">
                                            <b>Date</b>
                                            <p>{getOnlyUTCDateWithFormat(departure)}</p>
                                        </div>
                                        <div className="flex-1 pr-2">
                                            <b>Departure</b>
                                            <p>{formatUTCAMPM(departure)}</p>
                                        </div>
                                        <div className="flex-1">
                                            <b>Arrival</b>
                                            <p>{formatUTCAMPM(arrival)}</p>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        {carElement}
                        {hotelElement}
                    </main>
                </div>
            }

        } else if (name === "search_car") {
            let { date_of_journey, departure } = resArguments
            const dateTimeString = `${date_of_journey}`;
            let result = JSON.parse(functionResponse)
            if (result.result) {
                result = result.result[0]
                let data = result.substring(1, result.length - 1);;
                let availableCars = JSON.parse(data);
                message = <div className="book-now-block flight-details-box">
                    <div className="p-2">
                        I found these results.
                    </div>
                    <div className="d-flex ticket-box p-2">
                        <div className="ticket-label">
                            <b>From</b>
                        </div>
                        <div>
                            : {departure}
                        </div>
                    </div>
                    <div className="d-flex ticket-box p-2">
                        <div className="ticket-label">
                            <b>Date</b>
                        </div>
                        <div>
                            : {dateTimeString}
                        </div>
                    </div>
                    <div className="m-2" style={{ minWidth: 500 }}>
                        {
                            availableCars.map((item, index) => {
                                let { car_model, car_type, company, insurance_amount, price_per_day } = item
                                return <article className="row mx-0 pb-2 mb-2" key={index} style={{ borderBottom: "1px dashed #ddd" }}>
                                    <div className="col-6">
                                        <h5>{company}</h5>
                                        <b>Car Model: {car_model}</b>
                                        <div>Car Type: {car_type}</div>
                                    </div>
                                    <div className="col-6">
                                        <p className="flight-card__price styled-price">
                                            Insurance: {insurance_amount}
                                        </p>
                                        <p className="flight-card__price styled-price">
                                            Price Per Day: {price_per_day}
                                        </p>
                                    </div>
                                </article>
                            })
                        }
                    </div>
                </div>
            }
        } else if (name === "cancel_flight" || name === "cancel_car") {
            let { ticket_number } = resArguments
            let response = JSON.parse(functionResponse).response
            message = <div className="book-now-block">
                <div className="p-2">
                    {
                        response === "success" ? "Successfully cancelled." : response === "alreadyCancelled" ? "Look's like already you have cancelled." : "Invalid ticket number! Unable to cancel."
                    }
                </div>
                <div className="d-flex ticket-box p-2">
                    <div className="ticket-label">
                        <b>Ticket Number</b>
                    </div>
                    <div>
                        : {ticket_number}
                    </div>
                </div>
            </div>
        } else if (name === "auto_book_flight") {
            let response = JSON.parse(functionResponse).response
            if (isJson(response)) {
                let { responseMsg } = JSON.parse(response)
                message = <div className="book-now-block">
                    <div className="p-2">
                        {responseMsg}
                    </div>
                    {/* {
                        !responseMsg.includes("No flight") && <div className="m-2">
                            <button style={{ backgroundColor: "#1777eb", color: "#fff" }} className="btn btn-block" onClick={() => {
                                setReviewData(JSON.parse(response))
                            }}>Review</button>
                        </div>
                    } */}

                </div>
            } else {
                message = <div className="book-now-block">
                    <div className="p-2">
                        {response}
                    </div>
                </div>
            }
        } else if (name === "auto_flight_book") {
            let response = JSON.parse(functionResponse).response
            if (isJson(response)) {
                let { responseMsg } = JSON.parse(response)
                message = <div className="book-now-block">
                    <div className="p-2">
                        {responseMsg}
                    </div>
                    {
                        !responseMsg.includes("No data") && <div className="m-2">
                            <button style={{ backgroundColor: "#1777eb", color: "#fff" }} className="btn btn-block" onClick={() => {
                                setReviewData(JSON.parse(response))
                            }}>Review</button>
                        </div>
                    }
                </div>
            } else {
                message = <div className="book-now-block">
                    <div className="p-2">
                        {response}
                    </div>
                </div>
            }
        } else {
            message = <div>
                <span dangerouslySetInnerHTML={{ __html: formatString(functionResponse, false) }}></span>
            </div>
        }

        return message
    }

    async function getPromptReponse(prompt) {
        let postJson = {
            reqId: getUuidv4(),
            orgId: auth.activeCompany,
            userId: auth.loginId,
            prompt,
            projectId,
            designType
        }
        let result = await fetch("https://api.teqbahn.com/rag-flow-api/microPromptExecuteForUser", {
            method: "POST",
            mode: 'cors',
            redirect: 'follow',
            body: JSON.stringify(postJson),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        })
            .then((response) => response.json())
            .then((result) => {
                return result;
            })
            .catch((error) => {
                console.error('Error:', error);
            });

        let response = ""
        if (result.response) {
            response = JSON.parse(result.response).message.content
        }
        return response
    }

    async function updateExplanation() {
        let { id, message, userId } = chatMessage
        let editMessageText = "";

        if (message) {
            let data = JSON.parse(message)[0]
            const responseObject = JSON.parse(data.response);
            const functionResponse = JSON.parse(responseObject.functionResponse);
            const innerResponse = JSON.parse(functionResponse.response);

            // Add the explanation key
            let prompt = "Please write as a trip planner a few lines explaining this trip plan which was done taking into account the best of duration of flight and best price, best car rental and hotel applying customer preferences and company policy, Thanks. " + reviewData.bookedInfo
            let explanation = await getPromptReponse(prompt)
            // let explanation = "Test Update"
            innerResponse.explanation = explanation;
            reviewData.explanation = explanation
            setExplanationLoader(false)
            functionResponse.response = JSON.stringify(innerResponse);
            responseObject.functionResponse = JSON.stringify(functionResponse);
            data.response = JSON.stringify(responseObject);
            editMessageText = JSON.stringify([data])
        }

        let postJson = {
            reqId: getUuidv4(),
            messageId: id,
            channelId,
            agentName: chatMessage.aiAgentType,
            message: editMessageText
        };
        console.log("postJson", postJson)
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.updateAiAgentChat);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                console.log("Test", responseData)
            }
        });
    }

    function durationToMinutes(duration) {
        const hourRegex = /(\d+)\s*h(?:ours?)?/i;
        const minuteRegex = /(\d+)\s*m(?:inutes?)?/i;

        let hours = 0;
        let minutes = 0;

        // Extract hours
        const hourMatch = duration.match(hourRegex);
        if (hourMatch) {
            hours = parseInt(hourMatch[1], 10);
        }

        // Extract minutes
        const minuteMatch = duration.match(minuteRegex);
        if (minuteMatch) {
            minutes = parseInt(minuteMatch[1], 10);
        }

        return hours * 60 + minutes;
    }

    function sortingFlight(data) {
        let sortingData = data

        switch (sortBy) {
            case "time_to_travel":
                sortingData = data.sort((a, b) => durationToMinutes(a.flight_duration) - durationToMinutes(b.flight_duration));
                break;
            case "altitude":
                sortingData = data.sort((a, b) => a.altitude - b.altitude);
                break;
            case "price":
                sortingData = data.sort((a, b) => a.ticket_price.economy.single - b.ticket_price.economy.single);
                break;
        }

        return sortingData
    }

    function getTicketInfo(details) {
        let { flight, booking_info, status, car, hotel } = details
        let { flight_number, airline, departure_time, arrival_time } = flight
        let { seat_no, from, to, ticket_number } = booking_info
        let departure = new Date(departure_time)
        let arrival = new Date(arrival_time)

        let carElement;
        if (car) {
            let { company, car_type, car_model, insurance_amount, price_per_day, start_date, end_date } = car

            carElement = <React.Fragment>
                <div className="d-flex py-2" style={{ borderTop: "1px dashed #4648a1" }}>
                    <div className="flex-1">
                        <div>
                            <b>Car</b>
                            <div>
                                {company} <br />
                                {car_model} <br />
                                {car_type}
                            </div>
                        </div>

                    </div>
                    <div className="flex-1">
                        <b>Date</b>
                        <div>{getDateWithFormat(new Date(start_date).getTime())} - {getDateWithFormat(new Date(end_date).getTime())}</div>
                    </div>
                    <div className="flex-1">
                        <div>
                            <b>Price Per Day</b>
                            <div>{price_per_day}</div>
                        </div>
                        <div>
                            <b>Insurance</b>
                            <div>{insurance_amount}</div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        }

        let hotelElement;
        if (hotel) {
            let { name, location, amount, start_date, end_date, numberOfBeds, typeOfBed, bookedRoom } = hotel

            hotelElement = <div className="d-flex py-2" style={{ borderTop: "1px dashed #4648a1" }}>
                <div className="flex-1">
                    <div>
                        <b>Room : </b> {bookedRoom}
                    </div>
                    <div>
                        <b>Hotel : </b>
                        <div>
                            {name} <br />
                            {location} <br />
                        </div>
                    </div>
                </div>
                <div className="flex-1 px-2">
                    <b>Date</b>
                    <div>{getDateWithFormat(new Date(start_date).getTime())} - </div>
                    <div>{getDateWithFormat(new Date(end_date).getTime())}</div>

                </div>
                <div className="flex-1">
                    <div>
                        <b>Number Of Beds : </b> {numberOfBeds}
                    </div>
                    <div>
                        <b>Type Of Bed : </b> <span className="text-capitalize">{typeOfBed}</span>
                    </div>
                    <div>
                        <b>Price Per Day : </b> {amount}
                    </div>

                </div>
            </div>
        }

        return <main className="ticket-box p-3">
            <header className="d-flex">
                <div className="flex-1">
                    <h4>{ticket_number ? ticket_number : "Ticket Number"}</h4>
                    <h5>Seat No: {seat_no ? seat_no : "SN"}</h5>
                </div>
                <div className="mx-2">
                    <b>Flight</b>
                    <p>
                        {airline} <br />
                        {flight_number}
                    </p>
                </div>
                <div className="mx-5">
                    <b>Status</b>
                    <div>
                        <div className="badge badge-success text-capitalize">{status}</div>
                    </div>
                </div>
            </header>
            <div className="d-flex py-2" style={{ borderTop: "1px dashed #4648a1" }}>
                <section className="flight--general flex-1">
                    <div>
                        <b className="text-capitalize">{from}</b>
                    </div>
                    <div>
                        to
                    </div>
                    <div>
                        <b className="text-capitalize">{to}</b>
                    </div>
                </section>
                <section className="flight--TimeInfo flex-1">
                    <div className="d-flex">
                        <div className="flex-1 pr-2">
                            <b>Date</b>
                            <p>{getOnlyDateWithFormat(departure)}</p>
                        </div>
                        <div className="flex-1 pr-2">
                            <b>Departure</b>
                            <p>{formatAMPM(departure)}</p>
                        </div>
                        <div className="flex-1">
                            <b>Arrival</b>
                            <p>{formatAMPM(arrival)}</p>
                        </div>
                    </div>
                    <div style={{ border: "1px dashed #4648a1" }}>
                        <div className="text-center">
                            <b>UTC</b>
                        </div>
                        <div className="d-flex">
                            <div className="flex-1 pr-2">
                                <b>Date</b>
                                <p>{getOnlyUTCDateWithFormat(departure)}</p>
                            </div>
                            <div className="flex-1 pr-2">
                                <b>Departure</b>
                                <p>{formatUTCAMPM(departure)}</p>
                            </div>
                            <div className="flex-1">
                                <b>Arrival</b>
                                <p>{formatUTCAMPM(arrival)}</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {carElement}
            {hotelElement}
        </main>
    }

    function dataModify(data) {
        let modData = []
        for(let i = 0;i<data.length;i++) {
            modData.push(JSON.parse(data[i]))
        }

        return modData
    }
    function getTabData() {
        let str;
        let { flight, userPreference, explanation, hotel, car, bookedInfo } = reviewData
        switch (activeTab) {
            case "preferred-search-result":
                userPreference = JSON.parse(userPreference)
                let filteredJson = dataModify(JSON.parse(flight.filteredJson))
                let hotelData = dataModify(JSON.parse(hotel.filteredJson))
                let carData = dataModify(JSON.parse(car.filteredJson))

                let { maxAltitude, maxDuration, maxPrice, carType, carBrand, carRentalCompany, hotelRating, hotelTypeOfBed, hotelNumberOfBeds, hotelHighlights } = userPreference;
                str = <React.Fragment>
                    <CardToggle
                        title={"Flight"}
                        toggleMenuOpen={flightExpand}
                        toggleOnchange={() => {
                            setFlightExpand(!flightExpand)
                        }}
                    >
                        <div className="booking-preference">
                            <div className="p-2" style={{ border: "1px dashed #ddd" }}>
                                <h4>Preferred Details</h4>
                                <div className="row mt-2">
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label className="custom">Maximum Altitude</label>
                                            <div>{maxAltitude}</div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label className="custom">Maximum Duration</label>
                                            <div>{maxDuration} hours</div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label className="custom">Maximum Price</label>
                                            <div>{maxPrice}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-3">
                                <h4>Result</h4>
                                <div className="d-flex">
                                    <div className="p-2" style={{ backgroundColor: "#a2b8e8" }}>
                                        <div className="filter-header">
                                            Sort By
                                        </div>
                                        <div className="px-2 py-3">
                                            {
                                                [
                                                    { label: "Time to travel", value: "time_to_travel" },
                                                    { label: "Altitude", value: "altitude" },
                                                    { label: "Price", value: "price" },
                                                ].map((item) => {
                                                    let { label, value } = item
                                                    return <div className="form-check" key={value}>
                                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id={value} checked={sortBy === value} onChange={(e) => {
                                                            setSortBy(value)
                                                        }} />
                                                        <label className="form-check-label" htmlFor={value}>
                                                            {label}
                                                        </label>
                                                    </div>
                                                })
                                            }

                                        </div>
                                    </div>
                                    <div className="flex-1">
                                        {
                                            sortingFlight(filteredJson).map((item, index) => {
                                                let { airline, ticket_price, departure_time, arrival_time, flight_duration, altitude } = item
                                                console.log("test", item)
                                                let departure = new Date(departure_time)
                                                let arrival = new Date(arrival_time)
                                                return <article className="row mx-0 mb-2" key={index} style={{ borderBottom: "1px dashed #ddd" }}>
                                                    <div className="col">
                                                        <b>{airline}</b>
                                                        <div>
                                                            Altitude: {altitude}
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <time className="flight-card__time">
                                                            {formatAMPM(departure) + " - " + formatAMPM(arrival)}
                                                        </time>
                                                        <p className="flight-card__duration">{flight_duration}</p>
                                                    </div>
                                                    <div className="col">
                                                        {
                                                            Object.keys(ticket_price).map((item, index) => {
                                                                let obj = ticket_price[item]
                                                                let { single } = obj
                                                                return <div value={item} key={index}>{capitalize(item.replaceAll("_", " ")) + " - $" + single}</div>
                                                            })
                                                        }
                                                    </div>
                                                </article>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CardToggle>
                    <div className="mt-2">
                        <CardToggle
                            title={"Car"}
                            toggleMenuOpen={carExpand}
                            toggleOnchange={() => {
                                setCarExpand(!carExpand)
                            }}
                        >
                            <div className="booking-preference">
                                <div className="p-2" style={{ border: "1px dashed #ddd" }}>
                                    <h4>Preferred Details</h4>
                                    <div className="row mt-2">
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label className="custom">Car Type</label>
                                                <div>{carType ? carType : "Any"}</div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label className="custom">Car Brand</label>
                                                <div>{carBrand ? carBrand : "Any"}</div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label className="custom">Rental Company</label>
                                                <div>{carRentalCompany ? carRentalCompany : "Any"}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <h4>Result</h4>
                                    <div className="d-flex">
                                        <div className="flex-1">
                                            {
                                                carData.map((item, index) => {
                                                    let { car_model, car_type, company, insurance_amount, price_per_day } = item
                                                    return <article className="row mx-0 mb-2" key={index} style={{ borderBottom: "1px dashed #ddd" }}>
                                                        <div className="col-sm-4  px-2">
                                                            <h4>{company}</h4>
                                                            <h5>{car_model}</h5>
                                                            <span>{car_type}</span>
                                                        </div>
                                                        <div className="col-sm-4 px-2">
                                                            <p className="flight-card__price styled-price">
                                                                Insurance: ${insurance_amount}
                                                            </p>
                                                            <p className="flight-card__price styled-price">
                                                                Price Per Day: ${price_per_day}
                                                            </p>
                                                        </div>
                                                    </article>
                                                })
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </CardToggle>
                    </div>
                    <div className="mt-2">
                        <CardToggle
                            title={"Hotel"}
                            toggleMenuOpen={hotelExpand}
                            toggleOnchange={() => {
                                setHotelExpand(!hotelExpand)
                            }}
                        >
                            <div className="booking-preference">
                                <div className="p-2" style={{ border: "1px dashed #ddd" }}>
                                    <h4>Preferred Details</h4>
                                    <div className="row mt-2">
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label className="custom">Hotel Rating</label>
                                                <div>{hotelRating ? "Above " + hotelRating : "Any"}</div>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label className="custom">Type Of Bed</label>
                                                <div>{hotelTypeOfBed ? hotelTypeOfBed : "Any"}</div>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label className="custom">Number Of Beds</label>
                                                <div>{hotelNumberOfBeds ? hotelNumberOfBeds : "Any"}</div>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label className="custom">Highlights</label>
                                                <div>{hotelHighlights ? hotelHighlights.join(",") : "Any"}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <h4>Result</h4>
                                    <div className="d-flex">
                                        <div className="flex-1">
                                            {
                                                hotelData.map((item, index) => {
                                                    let { name, description, highlights, location, rating, amount, numberOfBeds, typeOfBed } = item
                                                    return <article className="row mx-0 mb-2" key={index} style={{ borderBottom: "1px dashed #ddd" }}>
                                                        <div className="col-sm-4 px-2">
                                                            <b>{name}</b>
                                                            <div>{description}</div>
                                                        </div>
                                                        <div className="col-sm-4 px-2">
                                                            {
                                                                highlights.map((highlight, hIndex) => {
                                                                    return <div className="highlight" key={hIndex}>{highlight}</div>
                                                                })
                                                            }
                                                            <hr />
                                                            <div>
                                                                <b>Type Of Bed:</b> <span className="text-capitalize">{typeOfBed}</span>
                                                            </div>
                                                            <div>
                                                                <b>Number Of Beds: </b> <span>{numberOfBeds}</span>
                                                            </div>
                                                            <hr />
                                                            {location}
                                                        </div>
                                                        <div className="col-sm-4 px-2">
                                                            <p className="flight-card__price styled-price">
                                                                Rating: {rating}
                                                            </p>
                                                            <p className="flight-card__price styled-price">
                                                                Price Per Day: $ {amount}
                                                            </p>
                                                        </div>
                                                    </article>
                                                })
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </CardToggle>
                    </div>
                </React.Fragment>
                break
            case "explanation":
                str = <div className="m-2 booking-preference">
                    <div className="form-group">
                        <label className="custom">Explanation</label>
                        {
                            explanationLoader ? <div style={{ position: "relative", height: 250 }}>
                                <Loader />
                            </div> : <div style={{ whiteSpace: "pre-line" }}>{explanation}</div>
                        }
                    </div>
                </div>
                break;
        }
        return str
    }

    return <React.Fragment>
        {getResponse()}
        {
            reviewData && <Modal
                visible={reviewData ? true : false}
                closeModal={() => {
                    setReviewData(null)
                }}
                size={"modal-xl"}
                heading={"Review"}
                body={<React.Fragment>
                    {getTicketInfo(JSON.parse(reviewData.bookedInfo))}
                    <CompTabs
                        optionsList={[{ label: "Preferred Search Result", value: "preferred-search-result" }, { label: "Explanation", value: "explanation" }]}
                        activeTab={activeTab}
                        tabBody={<div className='p-2'>
                            {getTabData()}
                        </div>}
                        onChange={(e) => {
                            setActiveTab(e.value)
                        }}
                    />
                </React.Fragment>
                } />
        }
    </React.Fragment>

}
