import React, { useEffect, useState, useRef, Suspense, lazy } from 'react';
import {
    Switch,
    Route,
    Link,
    useRouteMatch,
    useLocation,
    withRouter,
    useParams
} from "react-router-dom";
import ChatMenu from '../components/ChatMenu';
import BoardMenu from '../components/BoardMenu';
import MyConstant from "../config/MyConstant";
import { checkActiveAdmin, getGrpcClient, getGrpcPollingClient, getUuidv4, getValidTooltip, notifyNewWorkspaceMessage, coversionED, accumulation, commonEventList, getPageEventName, getAppInfo, checkvolunteersTypeTwo, defaultRedirectRoute, getAppsViewComponent, getControlFeature, toastMessageCountUpdate, getTabToken, getUserProfileData } from '../config/Common';
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import { getPageText, getValidLabels } from '../config/Common';
import { getToken, onMessageListener } from './firebase';
import IncomingCallModal from '../components/IncomingCallModal';
import MyConfig from '../config/MyConfig';
import callSound from '../assets/call/call1.wav';
import teqitLogo from '../assets/logo/logo.png';
import ReactTooltip from 'react-tooltip';
import { useSelector, useDispatch } from 'react-redux';
import { reSetAppsData, reSetAuthData, reSetBoardData, reSetCalendarData, reSetDailyReportOrgData, reSetDailyUpdateData, reSetExpensesData, reSetMembersData, reSetNotesData, reSetProjectDiagramData, reSetQatListData, reSetWorkpaceData, removeDailyReport, removeDailyReportOrg, removeDailyReportOrgData, removeExpensesData, removeWorkpaceData, setAuthData, setMultipleAuthData, setMultipleCalendarData, setMultipleLangugeData } from "../redux/actions";
import GlobalDomainCons from '../config/GlobalDomainCons';
import GlobalEventCons from '../config/GlobalEventCons';
import GlobalMsgCons from '../config/GlobalMsgCons';
import Modal from '../components/Modal';
import Loader from '../components/Loader';
import contexthelpJson from '../contexthelpvideos/contexthelpvideos.json'
import WorkspaceMenu from '../components/WorkspaceMenu';
import AppDefaultImage from '../assets/app-default.png'
import SwitchAccountAlert from '../components/SwitchAccountAlert';
import SwitchView from './mobile/SwitchView';
import AppsBuilder from './common/AppsBuilder';
import ScreenRecord from './common/screen-record/ScreenRecord';
import Graph from './common/simulation/Graph';
import GHTRoute from './GHTRoute';
import GuestChat from './common/GuestChat';
import GameSetup from './gaming/GameSetup';
import LanguageMenu from '../components/LanguageMenu';
import PinnedTab from './common/PinnedTab'
import InboxFeedbackNotification from '../components/InboxFeedbackNotification';
import ProviderUpdate from './common/provider/ProviderUpdate';

const pageNameAcc = 'workspaceTopMenu'
// WORK
const Workspace = lazy(() => import('./work/Workspace'));

//Customet Support
const SideMenu = lazy(() => import('./customerSupport/Menu/SideMenu'))
const DesignationRole = lazy(() => import('./common/DesignationRole'))
const AllFeedbackList = lazy(() => import('./customerSupport/AllFeedbackList'))

//Gaming
const GamingSideMenu = lazy(() => import('./gaming/Menu/SideMenu'))


//common
const Preferences = lazy(() => import('./common/Preferences'))
const AvailableFeatures = lazy(() => import('./common/AvailableFeatures'))
const CallHostManagement = lazy(() => import('./common/CallHostManagement'))
const Feedback = lazy(() => import('./common/feedback/Feedback'))
const FeedbackAdd = lazy(() => import('./common/feedback/FeedbackAdd'))
const FeedbackEdit = lazy(() => import('./common/feedback/FeedbackEdit'))
const OrgFeedback = lazy(() => import('./common/orgfeedback/OrgFeedback'))
const OrgFeedbackAdd = lazy(() => import('./common/orgfeedback/OrgFeedbackAdd'))
const OrgFeedbackEdit = lazy(() => import('./common/orgfeedback/OrgFeedbackEdit'))
const FeedbackList = lazy(() => import('./common/orgfeedback/OrgFeedbackList'))
const OrgFeedbackStatus = lazy(() => import('./common/orgfeedback/OrgFeedbackStatus'))
const ProjectDiagram = lazy(() => import('./common/ProjectDiagram'))
const ProjectDiagramOntology = lazy(() => import('./common/ProjectDiagramOntology'))
const Analytics = lazy(() => import('./common/Analytics'))
const Calendar = lazy(() => import('./common/calendar/Calendar'))
const CalendarMessage = lazy(() => import('./common/calendar/CalendarMessage'))
const Qat = lazy(() => import('./common/QAT/Qat'))
const CreateQat = lazy(() => import('./common/QAT/CreateQat'))
const EditQat = lazy(() => import('./common/QAT/EditQat'))
const QatStatus = lazy(() => import('./common/QAT/QatStatus'))
const AppManage = lazy(() => import('./common/AppManage'))
const AppsView = lazy(() => import('./common/AppsView'))
const JoinMeetingCall = lazy(() => import('./common/call/JoinMeetingCall'))
const Call = lazy(() => import('./common/call/Call'))
const IncomingCall = lazy(() => import('./common/call/IncomingCall'))
const JoinCall = lazy(() => import('./common/call/JoinCall'))
const CallThanks = lazy(() => import('./common/CallThanks'))
const Company = lazy(() => import('./common/Company'));
const Members = lazy(() => import('./common/Members'));
const Launchpad = lazy(() => import('./common/Launchpad'))
const CompanySetup = lazy(() => import('./common/CompanySetup'))
const CallEnded = lazy(() => import('./common/CallEnded'))
const MeetingThanks = lazy(() => import('./common/MeetingThanks'))
const Chat = lazy(() => import('./common/Chat'))
const SwitchAccount = lazy(() => import('./common/SwitchAccount'));
const CloudProviderUserMapping = lazy(() => import('./common/cloud/CloudProviderUserMapping'))
const MyProfile = lazy(() => import('./common/MyProfile'))
const ContextHelp = lazy(() => import('../components/ContextHelp'))
const InboxList = lazy(() => import('./common/InboxList'))
const Notes = lazy(() => import('./common/Notes/Notes'))
const HiddenChannels = lazy(() => import('./common/HiddenChannels'))
const UserSessionReport = lazy(() => import('./common/UserSessionReport'))
const HolidayDate = lazy(() => import('./common/HolidayDate'))
const HelpFaq = lazy(() => import('./common/HelpFaq'))
const DocumentSummarizer = lazy(() => import('./common/ai/DocumentSummarizer'))
const DocsNotification = lazy(() => import('../components/DocsNotification'))

const Docs = lazy(() => import('./common/Docs'))
const DocsWrite = lazy(() => import('./common/DocsWrite'))
const OrgRequest = lazy(() => import('./OrgRequest'))
const ChatSearch = lazy(() => import('./common/ChatSearch'))
const Guest = lazy(() => import('./common/calendly/Guest'))
const Event = lazy(() => import('./common/calendly/Event'))
const MailConfig = lazy(() => import('./common/MailConfig'))
const JoinCalendlyCall = lazy(() => import('./common/calendly/JoinCalendlyCall'))
const ProjectDiagramList = lazy(() => import('./common/ProjectDiagramList'))
const TeqDraw = lazy(() => import('./common/teqdraw/TeqDraw'))
const TeqDrawWrite = lazy(() => import('./common/teqdraw/TeqDrawWrite'))
const TeqPitchWrite = lazy(() => import('./common/teqpitch/TeqPitchWrite'))
const TeqPitch = lazy(() => import('./common/teqpitch/TeqPitch'))
const ChatModels = lazy(() => import('./common/ai/ChatModels'))
const SheetsWrite = lazy(() => import('./common/sheets/SheetsWrite'))
const Sheets = lazy(() => import('./common/sheets/Sheets'))
const TvManagement = lazy(() => import('./common/TvManagement'))
const TeqitTvView = lazy(() => import('./common/TeqitTvView'))
const TeqitTv = lazy(() => import('./common/teqittv/TeqitTv'))


const DonationForm = lazy(() => import('./common/donation/DonationForm'))
const DonationInventory = lazy(() => import('./common/donation/DonationInventory'))
const MessageNotificationSetup = lazy(() => import('./common/MessageNotificationSetup'))
const ExternalAppUserMapping = lazy(() => import('./common/openmrs/ExternalAppUserMapping'))
const CommunityServiceWorkspace = lazy(() => import('./communityService/CommunityServiceWorkspace'))
const Apps = lazy(() => import('./work/Apps'))
const AppsManagement = lazy(() => import('./work/AppsManagement'))

const Client = lazy(() => import('./work/manage/Client'))
const Project = lazy(() => import('./work/Project'))






let globalStream;
let callaudio;
let intervalChannelId;
let intervalSessionId;
let intervalLoginStatus;
let intervalUserPolling;
let intervalPolling;
let activeStatusIntervalId;

const besidesBeta = ["eng", "tam"];
const besidesPartial = ["som", "sna", "ibo", "orm", "hau"];

function CommonWorkspaceRoute(props) {
    // The `path` lets us build <Route> paths that are
    // relative to the parent route, while the `url` lets
    // us build relative links.

    let { callWindowData, setCallWindowData, callWindowStatus, setCallWindowStatus, setMessageId, messageIdRef, setPollCall, pollCall, setMenuStatus } = props;

    const inputReference = useRef(null);
    const { companyId, workspacetype } = useParams()
    let { path, url } = useRouteMatch();
    path = path.replace(":companyId", companyId);
    path = path.replace(":workspacetype", workspacetype);

    const location = useLocation();
    const [subRoute, setSubRoute] = useState(workspacetype);
    const [menuOpen, setMenuOpen] = useState(true);
    const [sidemenuOpen, setSidemenuOpen] = useState(true);
    const [activeCompanyId, setActiveCompanyId] = useState("");
    const [activeCompanyOwner, setActiveCompanyOwner] = useState("");
    const [activeCompanyName, setActiveCompanyName] = useState("");
    const [activeCompanyColor, setActiveCompanyColor] = useState("");
    const [loadCount, setLoadCount] = useState(1);
    const [firstName, setFirstName] = useState("");
    const [loggedEmail, setLoggedEmail] = useState("");
    const [fullView, setFullView] = useState(true);
    const [appsView, setAppsView] = useState(false);
    const [appsViewPage, setAppsViewPage] = useState(false);
    const [appsLayout, setAppsLayout] = useState(false);
    let [pageText, setPageText] = useState({});
    let [messageCountTrigger, setMessageCountTrigger] = useState(true);
    let [incomingCallModal, setIncomingCallModal] = useState(false);
    let [incomingCallData, setIncomingCallData] = useState({});
    let [feedId, setFeedId] = useState("");
    let [openedApps, setOpenedApps] = useState([])
    let [openedAppsLoader, setOpenedAppsLoader] = useState({})
    let [activeWindow, setActiveWindow] = useState("")
    let [lastActiveWindow, setLastActiveWindow] = useState("")
    let [minimizedAppsWindow, setMinimizedAppsWindow] = useState(false)
    let [activeToken, setActiveToken] = useState("")
    let [companyList, setcompanyList] = useState([]);
    let [calendarChannel, setCalendarChannel] = useState("");
    let [calendarChannelUnreadMessageCount, setCalendarChannelUnreadMessageCount] = useState(0);
    let [newBoardArrived, setNewBoardArrived] = useState(false);
    let [newChannelArrived, setNewChannelArrived] = useState(false);
    let [newCompanyCreated, setNewCompanyCreated] = useState(false);
    let [activeUserCompanyColor, setActiveUserCompanyColor] = useState("");
    let [languagePreference, setLanguagePreference] = useState("");
    let [companyType, setCompanyType] = useState("");
    let [apps, setApps] = useState([])
    let [toggleUserMenu, setToggleUserMenu] = useState(false)
    let [toggleUserLanguage, setToggleUserLanguage] = useState(false)
    let [memberDeactiveUpdated, setMemberDeactiveUpdated] = useState(false)
    let [taskChannelUpdated, setTaskChannelUpdated] = useState(false)
    let [notesChannelUpdated, setNotesChannelUpdated] = useState(false)
    let [inboxUpdated, setInboxUpdated] = useState(false)
    let [unreadMsgList, setUnreadMsgList] = useState({})

    let [clientUpdated, setClientUpdated] = useState(false);
    let [projectUpdated, setProjectUpdated] = useState(false);
    let [taskUpdated, setTaskUpdated] = useState(false);
    let [teamUpdated, setTeamUpdated] = useState(false);
    let [expenseCategoryUpdated, setExpenseCategoryUpdated] = useState(false);
    let [memberJoinUpdated, setMemberJoinUpdated] = useState(false);
    let [calendarUpdated, setCalendarUpdated] = useState(false);
    let [vsCodeMappingData, setVsCodeMappingData] = useState({});
    let [boardMenuText, setBoardMenuText] = useState({});
    let [chatMenuText, setChatMenuText] = useState({});
    let [activeUserType, setActiveUserType] = useState("");
    let [logo, setLogo] = useState("");
    let [systemLevelRole, setSystemLevelRole] = useState(GlobalMsgCons.systemLevelRoleMember);
    let [feedChannelUnreadMessageCount, setFeedChannelUnreadMessageCount] = useState(0);
    let [openMRSChannelUnreadMessageCount, setOpenMRSChannelUnreadMessageCount] = useState(0);

    let [contextHelp, setContextHelp] = useState("");
    let [toggleUserStatus, setToggleUserStatus] = useState(false);
    let [userStatus, setUserStatus] = useState("available");
    let [searchText, setSearchText] = useState("");
    let [tempSearchText, setTempSearchText] = useState("");
    let [searchDisplay, setSearchDisplay] = useState(false);
    let [searchSubmit, setSearchSubmit] = useState(false);
    let [channelId, setChannelId] = useState("");
    let [searchDropdown, setSearchDropdown] = useState(false);
    let [channelList, setChannelList] = useState({});
    let [checkedChannelList, setCheckedChannelList] = useState([]);
    let [checkedSearchTypeList, setCheckedSearchTypeList] = useState([]);
    let [allChannelList, setAllChannelList] = useState({});
    let [hideUnhideChannels, setHideUnhideChannels] = useState(false);
    let [dailyReportUpdate, setDailyReportUpdate] = useState(false);
    let [dailyReportDateUpdate, setDailyReportDateUpdate] = useState("");
    let [dayEntryUpdate, setDayEntryUpdate] = useState(false);
    let [dayEntryDateUpdate, setDayEntryDateUpdate] = useState("");
    let [dayExpenseUpdate, setDayExpenseUpdate] = useState(false);
    let [dayExpenseDateUpdate, setDayExpenseDateUpdate] = useState("");
    let [newMsgArrived, setNewMsgArrived] = useState(false);
    let [refreshCompanyDetails, setRefreshCompanyDetails] = useState(false);
    let [msgBoxUnreadCount, setMsgBoxUnreadCount] = useState(0);
    let [controlFeatureData, setControlFeatureData] = useState({ design: false })
    let [toggleRightMenu, setToggleRightMenu] = useState(false);

    let [activeDocsId, setActiveDocsId] = useState("");
    let [activeSheetId, setActiveSheetId] = useState("");
    let [activeDrawId, setActiveDrawId] = useState("");
    let [activePitchId, setActivePitchId] = useState("");
    let [appWindowStatus, setAppWindowStatus] = useState("");
    let [shareMessage, setShareMessage] = useState("");
    let [leaveRequest, setLeaveRequest] = useState(false);
    let [headerView, setHeaderView] = useState(true);
    let [pinTabInfo, setPinTabInfo] = useState({
        id: "",
        type: ""
    });
    let [aiChannelId, setAiChannelId] = useState("");
    const [updateModal, setUpdateModal] = useState(false);
    const [accessVerify, setAccessVerify] = useState(false);
    const [accessVerifyLoader, setAccessVerifyLoader] = useState(true);
    const [allChannelUnreadCount, setAllChannelUnreadCount] = useState(0);
    const [administrationUrls, setAdministrationUrls] = useState({});
    const [privateSupportChannelId, setPrivateSupportChannelId] = useState("");
    const [communitySupportChannelId, setCommunitySupportChannelId] = useState("");
    const [communityChannelUnreadCount, setCommunityChannelUnreadCount] = useState(0);
    const [privateChannelUnreadCount, setPrivateChannelUnreadCount] = useState(0);
    let [helpContextWindow, setHelpContextWindow] = useState(false);
    let [helpContextWindowSize, setHelpContextWindowSize] = useState("");
    let [helpContextVidUrl, setHelpContextVidUrl] = useState("");
    let [docsSummarizerDataResponse, setDocsSummarizerDataResponse] = useState({});

    //Poll Data
    let [pollChannel, setPollChannel] = useState({});

    let [pollUser, setPollUser] = useState({});
    let [pollChannelUnreadCount, setPollChannelUnreadCount] = useState({});
    let [channelResponseList, setChannelResponseList] = useState({});
    let [pollBoardChangesStatus, setPollBoardChangesStatus] = useState({});
    let activeCompanyIdRef = useRef("");
    let channelIdRef = useRef("");
    let cIdListRef = useRef([]);
    let boardIdRef = useRef("");
    let memberListRef = useRef([]);
    let docsSummarizerRoutedRef = useRef("");
    let pollUserRef = useRef({});
    let pollChannelUnreadCountRef = useRef({});
    let channelResponseListRef = useRef("");
    let callActiveChannelId = useRef("");
    let userCallStatus = useRef("");
    let pollDeviceInfo = useRef({});
    let pollingResponseData = useRef("");

    let [aiDocsSuccessUpdated, setAiDocsSuccessUpdated] = useState(false);
    let [aiDocsFileIndexed, setAiDocsFileIndexed] = useState(false);
    let [screenRecord, setScreenRecord] = useState(false);
    let [userLoggedOut, setUserLoggedOut] = useState(false);
    let [joinOrgRefresh, setJoinOrgRefresh] = useState(false);
    let [docsNotificationMsg, setDocsNotificationMsg] = useState(false);
    let [isPartialLanguage, setIsPartialLanguage] = useState(false);
    let [providerModal, setProviderModal] = useState(false);
    let [providerModalAsk, setProviderModalAsk] = useState(false);




    const previousValue = useRef(null);

    const auth = useSelector(state => state.authReducer);
    const languageReducer = useSelector(state => state.languageReducer);

    let { loginId } = auth;
    const dispatch = useDispatch();
    let statusTypes = [{ key: getValidLabels(pageText, 'activeStatusTxt'), value: 'available', value_2: "onCall" },
    { key: getValidLabels(pageText, 'awayStatusTxt'), value: 'steppedAway', value_2: "" }, { key: getValidLabels(pageText, 'notAvailableStatusTxt'), value: 'notAvailable', value_2: "notOnline" },]
    const searchTypeList = [
        { key: "channels", name: getValidLabels(chatMenuText, 'channelsSideMenuTxt') },
        { key: "directMessages", name: getValidLabels(chatMenuText, 'directMessagesSideMenuTxt') },
        { key: "notes", name: getValidLabels(pageText, 'notesSideMenuTxt') },
        { key: "feed", name: getValidLabels(chatMenuText, 'feedChannelSideMenuTxt') },
        { key: "feedback", name: "Org Feedback" },
        { key: "systemFeedback", name: getValidLabels(pageText, 'systemFeedbackmenuTxt') },
        { key: "docs", name: getValidLabels(pageText, 'docsSidemenuTxt') },
    ]
    useEffect(() => {
        console.log("common workspace route")
        pollDeviceInfo.current = getAppInfo()
        pollSetup();
        setFirstName(auth.firstName)
        setLoggedEmail(auth.loggedEmail)
        updateWindowDimensions();
        tokenSetup();
        checkloginStatusTimeout();
        localStorage.removeItem(MyConfig.project_LocalStorage + "toastMsgData")


        function handleClickOutside(e) {
            if (e.target.className !== undefined && typeof e.target.className === "string" && !e.target.className.includes("search-language-menubar") && !e.target.className.includes("user-icon-menu") && !e.target.className.includes("userstatus_txt_box") && !e.target.className.includes("aerostatus")) {
                setToggleUserMenu(false)
                setToggleUserLanguage(false)
                setToggleUserStatus(false)
            }
        }
        setLanguagePreference(auth.languagePreference)
        window.addEventListener("click", handleClickOutside);

        return () => {
            //componentWillUnmount
            if (globalStream !== undefined) {
                globalStream.cancel()
            }
            window.removeEventListener("click", handleClickOutside);
            clearInterval(intervalSessionId);
            clearInterval(intervalLoginStatus)
            clearInterval(intervalUserPolling)
            clearTimeout(intervalPolling)
            clearTimeout(activeStatusIntervalId);
        }
    }, [])

    useEffect(() => {
        let prev = previousValue.current;
        toastMessageCountUpdate(prev, activeCompanyId, companyList, unreadMsgList)
        previousValue.current = unreadMsgList;
    }, [unreadMsgList]);

    useEffect(() => {
        if (auth.fromMobileAccess) {
            setHeaderView(false)
        }
    }, [auth.fromMobileAccess])

    useEffect(() => {
        if (callWindowData) {
            callActiveChannelId.current = callWindowData.channelId
            setActiveWindow("")
            onlineMemberStatusTimeOut()
        } else {
            callActiveChannelId.current = ""
            setCallWindowStatus("")
            clearTimeout(activeStatusIntervalId);
        }
    }, [callWindowData])

    useEffect(() => {
        if (activeWindow && callWindowData) {
            setCallWindowStatus("minimized")
        }
    }, [activeWindow])


    useEffect(() => {
        if (companyId !== undefined && companyId !== "login") {
            dispatch(setMultipleAuthData({
                activeCompany: companyId
            }))
            setOpenedApps([]);
            setLastActiveWindow("")
            setActiveCompanyId(companyId);
            activeCompanyIdRef.current = companyId
            pollChannelUnreadCountRef.current = {};
            channelResponseListRef.current = "";
        }
    }, [companyId]);

    useEffect(async () => {
        let pageText = await getPageText('users');
        let boardMenuText = await getPageText('boardMenu');
        let chatMenuText = await getPageText('chatMenu');
        setPageText(pageText);
        setBoardMenuText(boardMenuText)
        setChatMenuText(chatMenuText)
    }, [languagePreference])


    useEffect(() => {
        getCompanyList();
    }, [newCompanyCreated, joinOrgRefresh])


    useEffect(() => {
        let pathname = props.location.pathname;
        if (pathname.includes("/docs-summarizer")) {
            docsSummarizerRoutedRef.current = true
        } else {
            docsSummarizerRoutedRef.current = ""
        }

    }, [props.location.pathname]);

    useEffect(() => {
        if (activeUserType === GlobalMsgCons.roleTypeProvider) {
            getProviderInfo()
        }
    }, [activeUserType])

    function freqUserPoll() {
        intervalPolling = setTimeout(() => {
            pollSetup()
        }, 1000);
    }

    async function tokenSetup() {
        let token = await getToken();
        if (token !== undefined) {
            setTokenFound(token);
        }
        onMessageListener().then(payload => {
            let data = JSON.parse(payload.data.message);
            if (data.type === "JoinCall") {
                // incomingCall(data)
            }
        }).catch(err => console.log('failed: ', err));
    }


    function updateWindowDimensions() {
        setMenuOpen(window.innerWidth > 600)
    }

    function setTokenFound(token) {
        dispatch(setAuthData("deviceToken", token));
        registerDeviceToLive(token);
    }

    function incomingCall(data) {
        setIncomingCallModal(true);
        setIncomingCallData(data);
        notifyCall();

    }

    function setBoardId(boardId) {
        boardIdRef.current = boardId
    }



    function pollSetup() {
        let { sessionId, loginId } = auth;
        let postJson = {
            rId: getUuidv4(),
            uId: loginId,
            sId: sessionId,
            stId: getTabToken(),
            orgType: GlobalMsgCons.orgTypeCompany
        };



        if (activeCompanyIdRef.current) {
            postJson.oId = activeCompanyIdRef.current
        }

        if (channelIdRef.current) {
            postJson.cId = channelIdRef.current
        }
        if (cIdListRef.current.length > 0) {
            postJson.cIdList = cIdListRef.current
        }

        if (messageIdRef.current) {
            postJson.mId = messageIdRef.current
            if (callActiveChannelId.current) {
                postJson.callcId = callActiveChannelId.current;
                let userCallStatusMsg = userCallStatus.current === "onCall" ? GlobalMsgCons.active : GlobalMsgCons.inActive;
                postJson.ucs = userCallStatusMsg
            }
        }

        if (boardIdRef.current) {
            postJson.bId = boardIdRef.current
        }

        if (memberListRef.current.length) {
            postJson.memberList = memberListRef.current
        }
        if (docsSummarizerRoutedRef.current) {
            postJson.extraPollingFromPage = "docsSummarizer";
        }

        if (pollDeviceInfo.current) {
            postJson.deviceInfo = pollDeviceInfo.current
        }
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.pollSetDomain);
        request.setEvent(GlobalEventCons.globalPoll);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcPollingClient(props.grpcPollingClient).pollZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request.Please try again later');
                freqUserPoll()

            } else {
                freqUserPoll()
                let responseData = JSON.parse(response.getMessage());
                if (pollingResponseData.current !== JSON.stringify(responseData)) {
                    pollingResponseData.current = JSON.stringify(responseData)
                    console.log("**polling message arrived**")
                    let { userSession, userPoll, user, channel, call, getChannelUnReadCountList, getBoardChangesStatus, getMemberOnlineStatus, areThereMessagesWaiting, channelResponseList } = responseData;
                    if (userSession) {
                        validateUserSession(JSON.parse(userSession));
                    }
                    if (getMemberOnlineStatus) {
                        setMemberOnlineStatus(JSON.parse(getMemberOnlineStatus))
                    }
                    if (userPoll) {
                        userPollFrequent(JSON.parse(userPoll))
                    }
                    if (channel) {
                        setPollChannel(JSON.parse(channel))
                    }
                    if (call) {
                        setPollCall(JSON.parse(call))
                    }
                    if (user && pollUserRef.current !== user) {
                        pollUserRef.current = user
                        setPollUser(JSON.parse(user))
                    }

                    if (getChannelUnReadCountList && pollChannelUnreadCountRef.current !== getChannelUnReadCountList) {
                        pollChannelUnreadCountRef.current = getChannelUnReadCountList;
                        setPollChannelUnreadCount(JSON.parse(getChannelUnReadCountList))
                    }
                    if (channelResponseList && channelResponseListRef.current !== JSON.stringify(channelResponseList)) {
                        channelResponseListRef.current = JSON.stringify(channelResponseList);
                        setChannelResponseList(channelResponseList)
                    }
                    if (getBoardChangesStatus) {
                        setPollBoardChangesStatus(JSON.parse(getBoardChangesStatus))
                    }
                    if (userSession) {
                        let { docsSummarizerData } = JSON.parse(userSession)
                        if (docsSummarizerData) {
                            setDocsSummarizerDataResponse(JSON.parse(docsSummarizerData))
                        }
                    }

                    if (areThereMessagesWaiting) {
                        let { listOfMessageTypesWaiting } = JSON.parse(areThereMessagesWaiting);
                        if (listOfMessageTypesWaiting !== undefined) {
                            notifyUsersChanges(listOfMessageTypesWaiting)
                        }
                    }
                }

            }
        });
    }


    function validateUserSession(responseData) {
        let { listOfMessageTypesWaiting } = responseData;
        if (listOfMessageTypesWaiting !== undefined) {
            notifyUsersChanges(listOfMessageTypesWaiting)
        }
    }

    function callReject() {
        let { channelId, messageId } = incomingCallData.message
        updateCallStatus(loginId, channelId, messageId, "reject-call")
        windowClose()
    }

    function updateCallStatus(senderId, channelId, messageId, status) {
        let postJson = { messageId, channelId, memberId: senderId, status, reqId: getUuidv4() };
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.updateCallStatusSetEvent);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {

            }
        });
    }

    function callAnswer() {
        let { channelId, roomId, messageId } = incomingCallData.message
        let url = path + "/join-call/" + channelId + "/" + roomId + "/" + messageId;
        windowClose()
        props.history.push(url)
    }

    function windowClose() {
        clearInterval(intervalChannelId);
        setIncomingCallModal(false);
        setIncomingCallData({});
        callaudio.pause();
        callaudio.currentTime = 0;
    }

    function registerDeviceToLive(deviceToken) {
        var userId = auth.loginId;
        if (userId && deviceToken) {
            var deviceInfo = { deviceToken: deviceToken, os: "" }
            let postJson = {
                userId: userId,
                deviceInfo: JSON.stringify(deviceInfo),
                deviceToken,
                reqId: getUuidv4()
            };
            const request = new ZiFetchRequest();
            request.setDomain(GlobalDomainCons.userSetDomain);
            request.setEvent(GlobalEventCons.setDeviceInfoSetEvent);
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                    console.warn('Invalid Request. Please try again later');
                } else {
                    // console.log("deviceToken -> " + deviceToken + ", userId ->" + userId + ", Registered successfully to Server.")
                }
            });
        }
    }


    function getCompanyDetails(userId, organizationId) {
        if (userId && organizationId) {
            let postJson = { reqId: getUuidv4(), userId, organizationId };
            const request = new ZiFetchRequest();
            request.setDomain(GlobalDomainCons.companySetDomain);
            request.setEvent(GlobalEventCons.organizationSetEvent);
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                    console.warn('Invalid Request. Please try again later');
                } else {
                    response = JSON.parse(response.getMessage());
                    console.log("response", response)
                    if (response && response.organization) {
                        let { organizationName, themeColor, organizationType, feedChannelId, communitySupportChannelId, parentOrgId, requestFrom, logo } = response.organization;
                        let { parentOrgName, privateSupportChannelId, mailNotificationForChat } = response
                        setPrivateSupportChannelId(privateSupportChannelId ? privateSupportChannelId : "")
                        setCommunitySupportChannelId(communitySupportChannelId ? communitySupportChannelId : "")

                        let controlFeatureDetails = response.controlFeaturesForOrganization;
                        let controlFeature = getControlFeature(controlFeatureDetails)

                        let companyOwnerId = response.organization.userId;
                        let roleType = response.roleType;
                        let systemLevelRole = response.systemLevelRoleType

                        if (roleType === GlobalMsgCons.roleTypeMemberAdmin) {
                            roleType = "MemberAdmin"
                        } else if (roleType === GlobalMsgCons.roleTypeMember) {
                            roleType = "Member"
                        }
                        response.userId = "";

                        if (response.aiFeedChannelId && response.aiFeedChannelId !== "") {
                            setAiChannelId(response.aiFeedChannelId)
                        }
                        setActiveCompanyName(organizationName)
                        setActiveCompanyColor(themeColor)
                        setActiveCompanyOwner(companyOwnerId)
                        setCompanyType(organizationType)
                        setFeedId(feedChannelId)

                        let activeUserType = "";
                        if (userId === companyOwnerId) {
                            activeUserType = "Admin"
                            setActiveUserType(activeUserType);
                        } else {
                            activeUserType = roleType;
                            setActiveUserType(roleType)
                        }

                        setLogo(logo)
                        setSystemLevelRole(systemLevelRole);
                        dispatch(setMultipleAuthData({
                            activeCompanyName: organizationName,
                            activeCompanyColor: themeColor,
                            activeCompanyDetails: JSON.stringify(response.organization),
                            feedId: feedChannelId,
                            activeUserType,
                            systemLevelRole,
                            controlFeature,
                            requestFrom,
                            parentOrgInfo: parentOrgId ? { parentOrgId: parentOrgId, parentOrgName: parentOrgName } : {},
                            openMrsChannelId: (response.openMrsChannelId && response.openMrsChannelId !== "") ? response.openMrsChannelId : "",
                            openMrsExternalAppUserId: (response.openMrsExternalAppUserId && response.openMrsExternalAppUserId !== "") ? response.openMrsExternalAppUserId : "",
                            organizationType,
                            mailNotificationForChat: mailNotificationForChat ? mailNotificationForChat : false
                        }))
                        setAccessVerify(true)
                    } else {
                        setAccessVerify(false)
                        setAccessVerifyLoader(false)
                    }
                }
            });
        }
    }

    useEffect(() => {
        onRouteChanged();
        updateWindowDimensions()
    }, [location]);

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [companyList]);


    useEffect(() => {
        channelIdRef.current = channelId
    }, [channelId])

    useEffect(() => {
        if (memberDeactiveUpdated) {
            dispatch(setMultipleAuthData({
                activeCompany: "",
                activeCompanyName: "",
                activeCompanyColor: "",
                activeCompanyDetails: "",
                activeCompanyLogo: "",
                feedId: ""
            }));

            setActiveCompanyId("");
            getCompanyList();
            setMemberDeactiveUpdated(false)
            props.history.push(MyConstant.keyList.projectUrl + "/work/login/switch-account");
        }
    }, [memberDeactiveUpdated]);

    useEffect(async () => {
        let loginId = auth.loginId;
        if (!loginId) {
            let pathname = props.location.pathname;
            sessionStorage.setItem(MyConfig.project_LocalStorage + "referUrl", pathname);
            props.history.push(MyConstant.keyList.projectUrl);
        }
        if (companyId !== "login" && companyId !== undefined) {
            getCompanyDetails(loginId, companyId);
            getAdministrationUrls(companyId)
            getVSCodeMappingDetails();
            getOrgUnReadCountList()
            getUserLevelSidemenuControl()

            setActiveDocsId("")
            setActiveSheetId("")
            setActiveDrawId("")
            setActivePitchId("")
        } else if (location.pathname.includes("/switch-account") && location.pathname !== path + "/myprofile" && location.pathname !== path + "/feedback") {
            props.history.push(path + "/switch-account");
        }

        let activeMenuLayout = auth.activeMenuLayout;
        if (!activeMenuLayout) {
            activeMenuLayout = "sidemenu"
        }
        if (activeMenuLayout === "launchpad") {
            setAppsLayout(true)
            setSidemenuOpen(false)
        } else {
            setAppsLayout(false)
            setSidemenuOpen(true)
        }
        if (globalStream) {
            globalStream.cancel();
        }

        if (activeCompanyId !== "") {
            getCalendarChannel();
        }
        pollChannelUnreadCountRef.current = {};
        console.log("activecompanyId--->")
    }, [activeCompanyId]);

    useEffect(() => {
        setMenuStatus(sidemenuOpen)
    }, [sidemenuOpen])

    function resetExistCompanyData() {
        dispatch(reSetAppsData())
        dispatch(reSetWorkpaceData())
        dispatch(reSetExpensesData())
        dispatch(reSetQatListData())
        dispatch(reSetProjectDiagramData())
        dispatch(reSetCalendarData())
        dispatch(reSetBoardData())
        dispatch(reSetNotesData())
        dispatch(reSetDailyReportOrgData())
        dispatch(reSetDailyUpdateData())
        dispatch(reSetMembersData())
    }


    function notifyUsersChanges(listOfMessageTypesWaiting) {
        let refActiveCompanyId = activeCompanyIdRef.current ? activeCompanyIdRef.current : "";

        listOfMessageTypesWaiting.map((item) => {
            let itemArr = []
            if (item.includes("::")) {
                itemArr = item.split("::");
                item = itemArr[0]

                /*build release alert */
                if (itemArr.length > 0) {
                    if (!updateModal) {
                        askAlertModal(itemArr, item)
                    }

                }
                /*build release alert */
            }

            switch (item) {
                case "Client":
                    setClientUpdated(current => !current)
                    break;
                case "Project":
                    setProjectUpdated(current => !current)
                    break;
                case "Task":
                    setTaskUpdated(current => !current)
                    break;
                case "Team":
                    setTeamUpdated(current => !current)
                    break;
                case "ExpensesCategory":
                    setExpenseCategoryUpdated(current => !current)
                    break;
                case "Calendar":
                    dispatch(setMultipleCalendarData({
                        existData: {}
                    }))
                    setCalendarUpdated(current => !current)
                    break;
                case "MemberJoin":
                    setMemberJoinUpdated(current => !current)
                    break;
                case "MemberDeactive":
                    setMemberDeactiveUpdated(true)
                    break;
                case "TaskChannel":
                    setTaskChannelUpdated(current => !current)
                    break;
                case "OrgMsgArrived":
                    setNewMsgArrived(newMsgArrived => !newMsgArrived)
                    getOrgUnReadCountList();
                    break;
                case "Notes":
                    setNotesChannelUpdated(current => !current)
                    break;
                case "InboxMsgArrived":
                    setInboxUpdated(current => !current)
                    break;
                case "ControlFeature":
                case "SystemLevelRoleType":
                    getCompanyDetails(auth.loginId, refActiveCompanyId)
                    getUserLevelSidemenuControl();
                    break;
                case "sessionDeactivated":
                case "UserDeleted":
                    dispatch(reSetAuthData())
                    localStorage.removeItem(MyConfig.project_LocalStorage + "_userLogin");
                    localStorage.setItem(MyConfig.project_LocalStorage + "languagePreference", "eng");
                    setActiveCompanyId("")
                    props.history.push(MyConstant.keyList.projectUrl + "/work");
                    break;
                case "ChannelVisibility":
                    setHideUnhideChannels(hideUnhideChannels => !hideUnhideChannels)
                    break;
                case "DailyReport":
                    dispatch(removeDailyReportOrgData(itemArr[1], "selectedDateDetails"))
                    dispatch(removeDailyReport(itemArr[1]))
                    setDailyReportDateUpdate(itemArr[1])
                    setDailyReportUpdate(dailyReportUpdate => !dailyReportUpdate)
                    break;
                case "WorkDayEntry":
                    dispatch(removeWorkpaceData(itemArr[1]))
                    setDayEntryDateUpdate(itemArr[1])
                    setDayEntryUpdate(dayEntryUpdate => !dayEntryUpdate)
                    break;
                case "WorkExpenses":
                    dispatch(removeExpensesData(itemArr[1]))
                    setDayExpenseDateUpdate(itemArr[1])
                    setDayExpenseUpdate(dayExpenseUpdate => !dayExpenseUpdate)
                    break;
                case "OrganizationActive":
                case "OrganizationDeactive":
                    getCompanyDetails(auth.loginId, refActiveCompanyId)
                    setRefreshCompanyDetails(refreshCompanyDetails => !refreshCompanyDetails)
                    if (itemArr.length > 0 && itemArr.includes(refActiveCompanyId)) {
                        setActiveCompanyId("");
                        props.history.push(path + "/switch-account")
                    }
                    getCompanyList();
                    break;
                case "LeaveRequest":
                    setLeaveRequest(leaveRequest => !leaveRequest)
                    break;
                case "OrganizationAdded":
                    getCompanyDetails(auth.loginId, refActiveCompanyId)
                    break;
                case "AIDocsSummarizerFileUploaded":
                    setAiDocsSuccessUpdated(aiDocsSuccessUpdated => !aiDocsSuccessUpdated)
                    break;
                case "AIDocsSummarizerFileIndexed":
                    setAiDocsFileIndexed(aiDocsFileIndexed => !aiDocsFileIndexed)
                    break;
                case "JoinOrganization":
                    setJoinOrgRefresh(joinOrgRefresh => !joinOrgRefresh)
                    break;
            }
        })
    }


    function getThemeName() {
        let activeColor = activeUserCompanyColor ? activeUserCompanyColor : activeCompanyColor;
        let returnTheme = "";

        if (auth.theme === "dark") {
            returnTheme = "theme-dark"
        } else {
            switch (activeColor) {
                case "#2f3136":
                    returnTheme = "theme-black"
                    break;
                case "#eeeeee":
                    returnTheme = "theme-white"
                    break;
                case "#9c27b0":
                    returnTheme = "theme-purple"
                    break;
                case "#2196f3":
                    returnTheme = "theme-blue"
                    break;
                case "#4caf50":
                    returnTheme = "theme-green"
                    break;
                case "#ff9800":
                    returnTheme = "theme-orange"
                    break;
                case "#607d8b":
                    returnTheme = "theme-gray"
                    break;
                case "#ee595a":
                    returnTheme = "theme-dark-orange"
                    break;
                case "#dd4197":
                    returnTheme = "theme-pink"
                    break;
            }
        }
        return returnTheme;
    }

    function updateChannelUnReadCountIsEmpty() {
        setMessageCountTrigger(!messageCountTrigger)
    }

    function onRouteChanged() {
        setSearchDropdown(false)

        if (activeWindow) {
            setAppWindowStatus("resized")
        }
        if (callWindowData && callWindowStatus !== "minimized") {
            setCallWindowStatus("resized")
        }

        let pathname = props.location.pathname;
        //let subRoute = props.subRoute;
        let fullView = false,
            appsView = false,
            appsViewPage = false;



        if (pathname.includes("/call/") || pathname.includes("/join-call/") || pathname.includes("/join-meeting-call/") || pathname.includes("/call-thanks/") || pathname.includes("/call-ended/")) {
            fullView = true;
        }
        if (pathname.includes("/" + subRoute + "/apps/")) {
            appsView = true;
            appsViewPage = true;
        }
        if (!pathname.includes("/" + subRoute + "/apps/")) {
            setActiveWindow("")
        }
        if (pathname.includes("/chat/") || pathname.includes("/guest-chat/")) {
            setActiveWindow(lastActiveWindow);
            setMinimizedAppsWindow(true)
        } else {
            setChannelId("");
            setActiveWindow("")
            setMinimizedAppsWindow(false)
        }
        setSearchDisplay(false)
        setFullView(fullView);
        setAppsView(appsView);
        setAppsViewPage(appsViewPage);

        if (pathname.includes("/monitoring")) {
            let monitoringUrl = MyConfig.monitoringUrl
            openApps("monitoring", {
                "title": "Monitoring",
                "description": "",
                "appsUrl": monitoringUrl,
                "color": "#4e567d",
                "logo": "",
                "id": companyId,
                "iframeUrl": monitoringUrl,
                "administration": true
            })
        }
    }

    function getOrgUnReadCountList() {
        let postJson = { reqId: getUuidv4(), userId: auth.loginId };
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.userSetDomain);
        request.setEvent(GlobalEventCons.getOrgUnReadCountList);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                let result = responseData.result;

                if (result) {
                    let msgList = {}
                    Object.keys(result).map((companyId) => {
                        msgList[companyId] = parseInt(result[companyId])
                    })
                    setUnreadMsgList(msgList)
                }
            }
        });

    }

    async function logOutApi() {
        setCallWindowData("")
        setMessageId("")

        //userId: String,deviceToken:String
        let deviceToken = auth.deviceToken;
        var sessionId = auth.sessionId;
        dispatch(reSetAuthData())
        clearTimeout(intervalPolling)
        setUserLoggedOut(true)

        let postJson = { reqId: getUuidv4(), userId: loginId, deviceToken: deviceToken ? deviceToken : "", sessionId: sessionId ? sessionId : "" };
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.userSetDomain);
        request.setEvent(GlobalEventCons.userSignOutSetEvent);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                //let responseData = JSON.parse(response.getMessage());

            }
        });
        localStorage.removeItem(MyConfig.project_LocalStorage + "_userLogin");
        localStorage.setItem(MyConfig.project_LocalStorage + "languagePreference", "eng");
        setActiveCompanyId("")
        props.history.push(MyConstant.keyList.projectUrl + "/work");


    }


    function openApps(id, details) {
        if (!openedApps.includes(id)) {
            let list = [...openedApps];
            list.push(id);
            apps[id] = details;
            setApps(apps);
            openedAppsLoader[id] = { loading: true }
            setOpenedAppsLoader(openedAppsLoader)
            setActiveToken("");
            let userId = auth.loginId;
            let postJson = { reqId: getUuidv4(), userId };
            const request = new ZiFetchRequest();
            request.setDomain(GlobalDomainCons.userSetDomain);
            request.setEvent(GlobalEventCons.generateJwtSetEvent);
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                    console.warn('Invalid Request. Please try again later');
                } else {
                    let responseData = JSON.parse(response.getMessage());
                    setActiveToken(responseData.response)
                    setOpenedApps(list);
                    setActiveWindow(id)
                    setLastActiveWindow(id)
                }
            });

        } else {
            setLastActiveWindow(id)
            setActiveWindow(id)
            setSearchDisplay(false);
        }
        setAppsView(true);
    }

    function getCompanyList() {
        const userId = auth.loginId;
        let postJson = { reqId: getUuidv4(), sessionId: '123', userId };
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.companySetDomain);
        request.setEvent(GlobalEventCons.organizationListSetEvent);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                var response = responseData.response;
                setcompanyList(Object.values(response))
            }
        });
    }

    function getCalendarChannel() {
        const userId = auth.loginId;
        let organizationId = auth.activeCompany;
        if (organizationId && userId) {
            let postJson = { reqId: getUuidv4(), userId, organizationId };
            const request = new ZiFetchRequest();
            request.setDomain(GlobalDomainCons.companySetDomain);
            request.setEvent(GlobalEventCons.meetingChennalIdSetEvent);
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                    console.warn('Invalid Request.Please try again later');
                } else {
                    let responseData = JSON.parse(response.getMessage());
                    if (responseData.response) {
                        let channelId = responseData.response;
                        setCalendarChannel(channelId)
                    }
                }
            });
        }

    }

    async function setActiveCompanyList(company) {
        let { organizationId, organizationType } = company;


        if (company.userId === loginId) {
            updateOwnerSwichWorkspaceAPI(organizationId)
        }
        await resetExistCompanyData();

        props.history.push(MyConstant.keyList.projectUrl + "/" + defaultRedirectRoute(organizationId, organizationType))
    }

    function setCalendarUnreadMessageCount(data) {
        Object.keys(data).map((channelId) => {
            if (channelId === calendarChannel) {
                setCalendarChannelUnreadMessageCount(data[channelId])
            }
            if (channelId === feedId) {
                setFeedChannelUnreadMessageCount(data[channelId])
            }
        })
    }

    function notifyNewBoardArrived() {
        setNewBoardArrived(!newBoardArrived)
    }
    function notifyNewChannelArrived() {
        setNewChannelArrived(!newChannelArrived)
    }
    function notifyCompanyCreated() {
        setNewCompanyCreated(!newCompanyCreated)
    }

    function notifyNewMessage(sound) {
        var audio = new Audio(sound);
        audio.play();
    }

    function notifyCall() {
        callaudio = new Audio(callSound);
        callaudio.addEventListener('ended', function () {
            this.currentTime = 0;
            this.play();
        }, false);
        callaudio.play();
    }

    function closeIframeLoader(index) {
        let copyObject = { ...openedAppsLoader }
        if (copyObject[index]) {
            copyObject[index].loading = false;
        }

        setOpenedAppsLoader(copyObject)
    }

    function getVSCodeMappingDetails() {
        let postJson = { userId: loginId, orgId: activeCompanyId, reqId: getUuidv4() };
        //userId:String,orgId:String
        if (loginId && activeCompanyId) {
            const request = new ZiFetchRequest();
            request.setDomain(GlobalDomainCons.vsCodeSetDomain);
            request.setEvent(GlobalEventCons.mappedVSCodeServerDetailsForUserSE);
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                    console.warn('Invalid Request. Please try again later');
                } else {
                    let responseData = JSON.parse(response.getMessage());
                    var response = responseData.response;
                    if (response && Object.keys(response).length != 0) {
                        setVsCodeMappingData(response)
                    }
                }
            });
        }
    }

    function updateActiveStatus(status) {
        if (loginId && loginId !== "") {
            let postJson = { userId: loginId, status: status };
            let request = new ZiFetchRequest();
            request.setDomain(GlobalDomainCons.userSetDomain);
            request.setEvent(GlobalEventCons.updateUserOnlineStatusSetEvent);
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                    console.warn('Invalid Request. Please try again later');
                } else {
                    dispatch(setAuthData("steppedAwayStatus", status))
                }
            });
        }

    }
    function setMemberOnlineStatus(responseData) {
        let statusUpdates = responseData.statusMap[loginId];
        setUserStatus(statusUpdates)
    }

    function activeStatusReturn() {
        let selectStatus = statusTypes.filter((e) => {
            return e.value === userStatus || e.value_2 === userStatus;
        })
        if (selectStatus && selectStatus.length > 0) {
            selectStatus = selectStatus[0].key;
        }
        return selectStatus
    }

    function UserOnlineChange(status) {
        setUserStatus(status)
    }

    function checkloginStatusTimeout() {
        intervalLoginStatus = setTimeout(function () {
            let getloginStatus = localStorage.getItem(MyConfig.project_LocalStorage + "_userLogin");
            if (getloginStatus) {
                checkloginStatusTimeout()
            } else {
                redirectPage()
            }
        }, 5000);
    }

    function redirectPage() {
        dispatch(reSetAuthData());
        clearInterval(intervalLoginStatus)
        intervalLoginStatus = null;
        props.history.push(MyConstant.keyList.projectUrl + "/work");
    }

    function chatSearch() {
        if (tempSearchText.trim()) {
            setSearchText(tempSearchText)
            setSearchDisplay(true);
            setSearchSubmit(current => !current)
            setSearchDropdown(false)
            setActiveWindow("")
        }
    }

    function setupChannelList(type, list) {
        channelList[type] = list;
        list.map((channel) => {
            allChannelList[channel.id] = channel.name
        })
        setAllChannelList(allChannelList)
        setChannelList(channelList)
    }

    function checkSearchType(key) {
        if (checkedSearchTypeList.includes(key)) {
            const checkedSearchTypeListCopy = [...checkedSearchTypeList];
            const index = checkedSearchTypeListCopy.indexOf(key);
            checkedSearchTypeListCopy.splice(index, 1)

            let copyCheckedChannelList = [...checkedChannelList]
            if (channelList[key]) {
                for (let i = 0; i < channelList[key].length; i++) {
                    let id = channelList[key][i].id;

                    if (copyCheckedChannelList.includes(id)) {
                        let indexF = copyCheckedChannelList.indexOf(id);
                        copyCheckedChannelList.splice(indexF, 1)
                    }
                }
            } else if (key === "feed") {
                if (copyCheckedChannelList.includes(feedId)) {
                    let indexF = copyCheckedChannelList.indexOf(feedId);
                    copyCheckedChannelList.splice(indexF, 1)
                }
            } else if (key === "feedback" || key === "systemFeedback" || key === "dailyReport" || key === "notes" || key === "docs") {
                if (copyCheckedChannelList.includes(key)) {
                    let indexF = copyCheckedChannelList.indexOf(key);
                    copyCheckedChannelList.splice(indexF, 1)
                }
            }

            setCheckedChannelList(copyCheckedChannelList)
            setCheckedSearchTypeList(checkedSearchTypeListCopy)
        } else {
            let copyCheckedChannelList = [...checkedChannelList]
            if (channelList[key]) {
                for (let i = 0; i < channelList[key].length; i++) {
                    let id = channelList[key][i].id;

                    if (!copyCheckedChannelList.includes(id)) {
                        copyCheckedChannelList.push(id)
                    }
                }
            } else if (key === "feed") {
                copyCheckedChannelList.push(feedId)
            } else if (key === "feedback" || key === "systemFeedback" || key === "dailyReport" || key === "notes" || key === "docs") {
                copyCheckedChannelList.push(key)
            }
            setCheckedChannelList(copyCheckedChannelList)
            setCheckedSearchTypeList(checkedSearchTypeList.concat(key))
        }
    }


    function userPollFrequent(responseData) {
        let { isValidSession, unreadInboxCount } = responseData;
        if (!isValidSession) {
            logOutApi()
        }
        setMsgBoxUnreadCount(unreadInboxCount)

    }

    function getUserLevelSidemenuControl() {
        let loginId = auth.loginId;
        let organizationId = auth.activeCompany;
        let postJson = { reqId: getUuidv4(), userId: loginId, orgId: organizationId };
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.userSetDomain);
        request.setEvent(GlobalEventCons.userLevelControlFeaturesSetEvent);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                if (responseData.result !== null) {
                    let result = JSON.parse(responseData.result)
                    setControlFeatureData(result)
                }
                else {
                    controlFeatureData.design = false
                    setControlFeatureData(controlFeatureData)
                }
            }
        });
    }

    function askAlertModal(itemNewArr, item) {
        let askMessage = false
        itemNewArr.map((e => {
            let splitmsg = e.split("-");
            if (splitmsg.length > 0) {
                splitmsg.map((e) => {
                    if (e.toLowerCase() === "web" && item === "BuildRelease") {
                        askMessage = true;
                    }
                })
            }
        }))
        if (askMessage) {
            setUpdateModal(askMessage)
        }
    }

    function updateOwnerSwichWorkspaceAPI(organizationId) {
        let postJson = { reqId: getUuidv4(), orgId: organizationId, userId: loginId };
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.companySetDomain);
        request.setEvent(GlobalEventCons.updateOwnerSwichWorkspaceSetEvent);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                console.log("responseData", responseData)

            }
        });
    }

    function getAdministrationUrls(orgId) {
        let postJson = { reqId: getUuidv4(), orgId };
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.companySetDomain);
        request.setEvent(GlobalEventCons.getAdministrationUrl);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let json = JSON.parse(response.getMessage());
                setAdministrationUrls(json.response)
            }
        })
    }
    function getChatMenu() {
        return <ChatMenu
            setAllChannelUnreadCount={setAllChannelUnreadCount}
            shareMessage={shareMessage}
            setShareMessage={setShareMessage}
            searchGrpcClient={props.searchGrpcClient}
            taskChannelUpdated={taskChannelUpdated}
            memberJoinUpdated={memberJoinUpdated}
            appsLayout={false}
            activeCompanyId={activeCompanyId}
            notifyNewMessage={notifyNewMessage}
            newChannelArrived={newChannelArrived}
            notifyNewChannelArrived={notifyNewChannelArrived}
            notifyNewBoardArrived={notifyNewBoardArrived}
            setCalendarUnreadMessageCount={setCalendarUnreadMessageCount}
            messageCountTrigger={messageCountTrigger}
            pageText={chatMenuText}
            grpcPollingClient={props.grpcPollingClient}
            grpcClient={props.grpcClient}
            path={path}
            companyType={companyType}
            userOnlineStatus={userStatus}
            UserOnlineChange={UserOnlineChange}
            setupChannelList={setupChannelList}
            newMsgArrived={newMsgArrived}
            communityOrganization={auth.controlFeature.communityOrganization}
            communitySupportChannelId={communitySupportChannelId}
            privateSupportChannelId={privateSupportChannelId}
            setCommunityChannelUnreadCount={setCommunityChannelUnreadCount}
            setPrivateChannelUnreadCount={setPrivateChannelUnreadCount}
            pollUser={pollUser}
            pollChannelUnreadCount={pollChannelUnreadCount}
            setRequestReceivingOpenMRSChannelUnreadCount={setOpenMRSChannelUnreadMessageCount}
            languagePreference={languagePreference}
        />
    }

    function getBoardMenu() {
        return !auth.controlFeature.communityOrganization && <BoardMenu appsLayout={false} activeCompanyId={activeCompanyId} newBoardArrived={newBoardArrived} pageText={boardMenuText} grpcPollingClient={props.grpcPollingClient} grpcClient={props.grpcClient} companyType={companyType}
            path={path}
        />
    }

    function toggleTheme() {
        let tempTheme = auth.theme === "dark" ? "default" : "dark"
        dispatch(setMultipleAuthData({
            theme: tempTheme
        }))
    }

    function getAppsView(appsData) {

        let appsArgumentData = {
            path,
            grpcClient: props.grpcClient,
            languagePreference,
            appsData,
            simGrpcClient: props.simGrpcClient,
            pinTabInfo,
            setPinTabInfo,
            searchGrpcClient: props.searchGrpcClient,
            docsGrpcPollingClient: props.docsGrpcPollingClient,
            docsGrpcClient: props.docsGrpcClient,
            history: props.history,
            companyType,
            memberJoinUpdated
        }

        return getAppsViewComponent(appsArgumentData);
    }

    function getLogoView() {
        let str;
        if (logo && typeof logo !== undefined && logo !== null) {
            let split = logo.split(".")
            let processType = "companyLogo", fileType = split[1], docsId = split[0]
            let imagePath = MyConstant.keyList.apiURL + "vp?action=" + processType + "&key=" + docsId + "." + fileType + "&id=logos"

            str = <div className={`workspace-logo`} style={{
                backgroundImage: `url(${imagePath})`,
            }} />
        }

        return str
    }


    async function onlineMemberStatusTimeOut() {
        let responseData = await getMemberOnlineStatus()
        activeStatusIntervalId = setTimeout(function () {
            if (responseData) {
                // console.log("**->", responseData)
                if (responseData[loginId] && userCallStatus.current !== responseData[loginId]) {
                    userCallStatus.current = responseData[loginId]
                }
                onlineMemberStatusTimeOut()
            }
        }, 1000);
    }

    function getMemberOnlineStatus() {
        let loginId = auth.loginId;
        let postJson = { reqId: getUuidv4(), senderId: loginId, memberList: [loginId] };
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.pollSetDomain);
        request.setEvent(GlobalEventCons.getMemberOnlineStatusSetEvent);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        let result = new Promise(resolve => {
            getGrpcPollingClient(props.grpcPollingClient).pollZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                    console.warn('Invalid Request.Please try again later');
                } else {
                    let responseData = JSON.parse(response.getMessage());
                    resolve(responseData.statusMap);
                }
            });
        });
        return result;
    }

    function closeApps(app) {
        let removeOpenedApps = [...openedApps];
        let index = removeOpenedApps.indexOf(app);
        if (index >= 0) {
            removeOpenedApps.splice(index, 1);
            delete openedAppsLoader[app];
            setOpenedApps(removeOpenedApps)
            setOpenedAppsLoader(openedAppsLoader)

            if (app === lastActiveWindow) {
                setLastActiveWindow("")
            }
        }
    }

    async function getProviderInfo() {
        let postData = {
            grpcClient: props.grpcClient,
            postJson: { reqId: getUuidv4(), sessionId: '123', userId: loginId }
        }
        let responseMsg = await getUserProfileData(postData)
        if (responseMsg && providerModalAsk === false) {
            setProviderModalAsk(true)
            setProviderModal(true)
        }

    }

    function getRoute() {
        let str;
        switch (workspacetype) {
            case "customer-support":
                str = <React.Fragment>
                    <Route exact path={`${path}/designation`}>
                        <DesignationRole grpcClient={props.grpcClient} history={props.history} languagePreference={languagePreference} />
                    </Route>
                    <Route exact path={`${path}/guest-feedback`}>
                        <AllFeedbackList pollChannelUnreadCount={pollChannelUnreadCount} path={path} searchGrpcClient={props.searchGrpcClient} grpcClient={props.grpcClient} history={props.history} languagePreference={languagePreference} />
                    </Route>
                    <Route exact path={`${path}/guest-chat/:channelId`}>
                        <GuestChat
                            setChannelId={setChannelId}
                            pollChannel={pollChannel}
                            setCallWindowData={setCallWindowData}
                            activeWindow={activeWindow}
                            searchGrpcClient={props.searchGrpcClient}
                            docsGrpcClient={props.docsGrpcClient}
                            companyType={MyConfig.orgTypeCustomerSupport}
                            grpcClient={props.grpcClient}
                            history={props.history}
                            grpcPollingClient={props.grpcPollingClient}
                            path={path}
                            guestRole={true}
                            fromAdmin={true}
                            fromCustomerSupport={true}
                            fromCustomerSupportTeam={true}
                            openApps={openApps}
                        />
                    </Route>
                </React.Fragment>
                break;
            case "gaming-platform":
                str = <React.Fragment>
                    <Route exact path={`${path}/game/:channelId/:gameChatChannelId`}>
                        <GameSetup
                            grpcClient={props.grpcClient}
                            history={props.history}
                            grpcPollingClient={props.grpcPollingClient}
                            path={path}
                            cIdListRef={cIdListRef}
                            channelResponseList={channelResponseList}
                        />
                    </Route>
                </React.Fragment>
        }
        return str;
    }

    function getSideMenu() {
        let str;
        switch (workspacetype) {
            case "customer-support":
                str = <SideMenu
                    allChannelUnreadCount={allChannelUnreadCount + parseInt(feedChannelUnreadMessageCount) + parseInt(openMRSChannelUnreadMessageCount)}
                    activeDocsId={activeDocsId}
                    activeSheetId={activeSheetId}
                    activeDrawId={activeDrawId}
                    activePitchId={activePitchId}
                    activeUserType={activeUserType}
                    systemLevelRole={systemLevelRole}
                    activeCompanyId={activeCompanyId}
                    companyType={companyType}
                    location={location}
                    loginId={loginId}
                    activeCompanyOwner={activeCompanyOwner}
                    pageText={pageText}
                    calendarChannel={calendarChannel}
                    calendarChannelUnreadMessageCount={calendarChannelUnreadMessageCount}
                    feedId={feedId}
                    path={path}
                    contextHelp={contextHelp}
                    setContextHelp={setContextHelp}
                    feedChannelUnreadMessageCount={feedChannelUnreadMessageCount}
                    controlFeatureData={controlFeatureData}
                    aiChannelId={aiChannelId}
                    chatMenu={getChatMenu()}
                    boardMenu={getBoardMenu()}
                    administrationUrls={administrationUrls}
                    openApps={openApps}
                    privateSupportChannelId={privateSupportChannelId}
                    communitySupportChannelId={communitySupportChannelId}
                    privateChannelUnreadCount={privateChannelUnreadCount}
                    communityChannelUnreadCount={communityChannelUnreadCount}
                    openMRSChannelUnreadMessageCount={openMRSChannelUnreadMessageCount}
                    setScreenRecord={setScreenRecord}
                />
                break;
            case "gaming-platform":
                str = <GamingSideMenu
                    allChannelUnreadCount={allChannelUnreadCount + parseInt(feedChannelUnreadMessageCount) + parseInt(openMRSChannelUnreadMessageCount)}
                    activeDocsId={activeDocsId}
                    activeSheetId={activeSheetId}
                    activeDrawId={activeDrawId}
                    activePitchId={activePitchId}
                    activeUserType={activeUserType}
                    systemLevelRole={systemLevelRole}
                    activeCompanyId={activeCompanyId}
                    companyType={companyType}
                    location={location}
                    loginId={loginId}
                    activeCompanyOwner={activeCompanyOwner}
                    pageText={pageText}
                    calendarChannel={calendarChannel}
                    calendarChannelUnreadMessageCount={calendarChannelUnreadMessageCount}
                    feedId={feedId}
                    path={path}
                    contextHelp={contextHelp}
                    setContextHelp={setContextHelp}
                    feedChannelUnreadMessageCount={feedChannelUnreadMessageCount}
                    controlFeatureData={controlFeatureData}
                    aiChannelId={aiChannelId}
                    chatMenu={getChatMenu()}
                    boardMenu={getBoardMenu()}
                    administrationUrls={administrationUrls}
                    openApps={openApps}
                    privateSupportChannelId={privateSupportChannelId}
                    communitySupportChannelId={communitySupportChannelId}
                    privateChannelUnreadCount={privateChannelUnreadCount}
                    communityChannelUnreadCount={communityChannelUnreadCount}
                    openMRSChannelUnreadMessageCount={openMRSChannelUnreadMessageCount}
                    setScreenRecord={setScreenRecord}
                />
                break;
        }


        return str;
    }
    return (
        <div className={`theme-body ${getThemeName()}`}>
            {
                <React.Fragment>
                    {
                        !fullView && <div className="menu">
                            {
                                headerView && <div className="header">
                                    <div className="logo">
                                        <div className='version'>Version - {MyConstant.keyList.webReleaseVersion}</div>
                                        <img src={teqitLogo} alt="Logo" style={{ height: 40 }} />
                                    </div>
                                    <div className="header-content">
                                        <div className={`menu-toggle ${sidemenuOpen ? "active" : "inactivemenu"}`} onClick={() => {
                                            accumulation(pageNameAcc, getPageEventName("workspaceTopMenu", "sidemenuIconClicked"), auth.activeCompany, auth.loginId, props.grpcClient)
                                            if (appsViewPage) {
                                                setAppsView(!appsView)
                                            }

                                            if (appsLayout) {
                                                if (activeCompanyId) {
                                                    props.history.push(path + "")
                                                } else {
                                                    props.history.push(path + "/switch-account")
                                                }
                                            }
                                            setSidemenuOpen(!sidemenuOpen)
                                            setAppsLayout(false)
                                            setMenuOpen(!menuOpen)
                                        }} data-tip={getValidTooltip(pageText, 'sideMenuIconTooltipTxt')}>
                                            <i className="fa fa-bars"></i>
                                        </div>

                                        <div className="company d-flex align-items-center">
                                            {
                                                activeCompanyName && <React.Fragment>
                                                    {getLogoView()}
                                                    <div className='name'>{activeCompanyName}</div>
                                                </React.Fragment>
                                            }
                                        </div>
                                        {
                                            window.innerWidth > 600 ? <React.Fragment>
                                                <div className={`chat-header-search`}>
                                                    <div className={`chat-header-content ${!searchDropdown ? "d-none" : ""}`}>
                                                        <div className='chat-search-input'>
                                                            <div className='input ml-2'>
                                                                <input type="text" ref={inputReference} className='search-dropdown' placeholder={getValidLabels(pageText, 'searchIconTooltipTxt')} onKeyDown={(e) => {
                                                                    if (e.key === 'Enter') {
                                                                        chatSearch()
                                                                    }
                                                                }}
                                                                    onChange={(e) => {
                                                                        setTempSearchText(e.target.value)
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className='icon' onClick={() => {
                                                                accumulation(pageNameAcc, getPageEventName("workspaceTopMenu", "searchIconClicked"), auth.activeCompany, auth.loginId, props.grpcClient)
                                                                chatSearch()
                                                            }}>
                                                                <i className="fa fa-search" />
                                                            </div>
                                                            <div className='icon-close' onClick={() => setSearchDropdown(false)}>
                                                                <i className="fa fa-times" />
                                                            </div>
                                                        </div>
                                                        <div className='channel-list'>
                                                            {
                                                                searchTypeList.map((data, index) => {
                                                                    return <div className='channel-group' key={index}>
                                                                        <div className='channel-type'>
                                                                            <div className="check">
                                                                                <label className="check-label" htmlFor={"channel" + data.key}>
                                                                                    {data.name}
                                                                                </label>
                                                                                <input className="check-input" type="checkbox" value={data.key} id={"channel" + data.key} checked={checkedSearchTypeList.includes(data.key)} onChange={() => checkSearchType(data.key)} />
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            channelList[data.key] && channelList[data.key].map((data, index) => {
                                                                                return <div className='channel' key={data.id}>
                                                                                    <div className="check">
                                                                                        <label className="check-label" htmlFor={"channel" + data.id}>
                                                                                            {data.name}
                                                                                        </label>
                                                                                        <input className="check-input" type="checkbox" value={data.id} id={"channel" + data.id} checked={checkedChannelList.includes(data.id)} onChange={() => {
                                                                                            if (checkedChannelList.includes(data.id)) {
                                                                                                const checkedChannelListCopy = [...checkedChannelList];
                                                                                                const index = checkedChannelListCopy.indexOf(data.id);
                                                                                                checkedChannelListCopy.splice(index, 1)

                                                                                                setCheckedChannelList(checkedChannelListCopy)
                                                                                            } else {
                                                                                                setCheckedChannelList(checkedChannelList.concat(data.id))
                                                                                            }
                                                                                        }} />
                                                                                    </div>
                                                                                </div>
                                                                            })
                                                                        }
                                                                    </div>
                                                                })
                                                            }

                                                        </div>
                                                    </div>
                                                    {
                                                        !searchDropdown && <div className="input-group">
                                                            <input className="search" type="text" placeholder={getValidLabels(pageText, 'searchIconTooltipTxt')}
                                                                value={searchText}
                                                                onChange={(e) => console.log("e")}
                                                                onFocus={() => {
                                                                    setSearchDropdown(true)
                                                                    inputReference.current.focus();
                                                                }}
                                                            />
                                                            <div className="input-group-append">
                                                                <span className="input-group-text" data-tip={getValidTooltip(pageText, 'searchIconTooltipTxt')} onClick={() => {
                                                                    accumulation(pageNameAcc, getPageEventName("workspaceTopMenu", "searchIconClicked"), auth.activeCompany, auth.loginId, props.grpcClient)
                                                                    setSearchDropdown(true)
                                                                }}><i className="fa fa-search" /></span>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>

                                                <div className={`mt-2 mr-2 awayactive ${toggleUserStatus ? "active" : ""}`}>
                                                    <div className='userstatus_txt_box' onClick={() => {
                                                        accumulation(pageNameAcc, getPageEventName("workspaceTopMenu", "userStatusChanged"), auth.activeCompany, auth.loginId, props.grpcClient)
                                                        setToggleUserStatus(!toggleUserStatus);
                                                        setToggleUserLanguage(false);
                                                        setToggleUserMenu(false)
                                                    }} data-tip={getValidTooltip(pageText, 'userStatusTooltipTxt')}><div className={`mr-1 status_round ${userStatus == "available" || userStatus == "onCall" ? "stay" : userStatus == "steppedAway" ? "away-out" : "not-away"} `}></div>
                                                        {activeStatusReturn(userStatus)}
                                                        <i className="aerostatus fa fa-caret-down ml-1" style={{ fontSize: "14px" }}></i>
                                                    </div>
                                                    <div className='userstatusoptions'>
                                                        {
                                                            statusTypes && statusTypes.map((data, i) => {
                                                                return (<div key={i} className='status_list' onClick={() => {

                                                                    let statusupdate = data.value
                                                                    let getStatus = statusupdate
                                                                    setUserStatus(data.value)
                                                                    updateActiveStatus(getStatus)
                                                                }}>
                                                                    <div className='liststylesA'>
                                                                        <div className={`status_roundA ${data.value === "steppedAway" ? "orangRounA" : data.value === "notAvailable" || data.value === "notOnline" ? "orangRoundC" : ""}`}>
                                                                        </div>
                                                                    </div>
                                                                    <div className='liststylesB'>
                                                                        {data.key}
                                                                    </div>
                                                                    <div className='liststylesC'>
                                                                        {(data.value == userStatus || data.value_2 == userStatus) &&
                                                                            <i className="fa fa-arrow-circle-left" aria-hidden="true" style={{ color: "#000" }}></i>
                                                                        }
                                                                    </div>
                                                                </div>)
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <LanguageMenu
                                                    grpcClient={props.grpcClient}
                                                    setLanguagePreference={setLanguagePreference}
                                                    toggleUserLanguage={toggleUserLanguage}
                                                    setToggleUserLanguage={setToggleUserLanguage}
                                                    setToggleUserStatus={setToggleUserStatus}
                                                    setToggleUserMenu={setToggleUserMenu}
                                                    pageText={pageText}
                                                />


                                                <div className={`users-details-icon ${toggleUserMenu ? "active" : ""}`}>
                                                    <div className='icon user-icon-menu' onClick={() => {
                                                        accumulation(pageNameAcc, getPageEventName("workspaceTopMenu", "profileIconClicked"), auth.activeCompany, auth.loginId, props.grpcClient)
                                                        setToggleUserMenu(!toggleUserMenu)
                                                        setToggleUserLanguage(false)
                                                        setToggleUserStatus(false)
                                                    }} data-tip={getValidTooltip(pageText, 'userIconTooltipTxt')}>
                                                        <i className='fa fa-user-circle user-icon-menu' />
                                                    </div>
                                                    <div className='options'>
                                                        <div className='option-list' data-tip={getValidTooltip(pageText, 'profileSelectTooltipTxt')} onClick={() => { accumulation(pageNameAcc, getPageEventName("workspaceTopMenu", "myAccountClicked"), auth.activeCompany, auth.loginId, props.grpcClient) }}>
                                                            <Link className="" to={path + "/myprofile"}>
                                                                <i className='fa fa-user'></i>{getValidLabels(pageText, 'myProfileTopMenuTxt')}
                                                            </Link>
                                                        </div>

                                                        {GlobalMsgCons.roleTypeProvider === activeUserType && <div className='option-list' data-tip={""} onClick={() => { }}>
                                                            <Link className="" to={path + "/provider-update"}>
                                                                <i className='fa fa-user'></i>{"Provider Information"}
                                                            </Link>
                                                        </div>}

                                                        <div className='option-list' data-tip={getValidTooltip(pageText, 'preferencesSelectTooltipTxt')}
                                                            onClick={() => { accumulation(pageNameAcc, getPageEventName("workspaceTopMenu", "preferencesClicked"), auth.activeCompany, auth.loginId, props.grpcClient) }}
                                                        >
                                                            <Link className="" to={path + "/preferences"}>
                                                                <i className='fa fa-cog'></i>{getValidLabels(pageText, 'preferencesTopMenuTxt')}
                                                            </Link>
                                                        </div>
                                                        {
                                                            auth.activeCompany && <React.Fragment>
                                                                <div className='option-list' data-tip={getValidTooltip(pageText, 'hiddenChannelsTopMenuTxt')} onClick={() => { accumulation(pageNameAcc, getPageEventName("workspaceTopMenu", "hiddenChannelsClicked"), auth.activeCompany, auth.loginId, props.grpcClient) }}>
                                                                    <Link className="" to={path + "/hiddenchannels"}>
                                                                        <i className='fas fa-comment-slash	'></i>{getValidLabels(pageText, 'hiddenChannelsTopMenuTxt')}
                                                                    </Link>
                                                                </div>
                                                                <div className='option-list' data-tip={getValidTooltip(pageText, 'availableFeaturesTopMenuTxt')} >
                                                                    <Link className="" to={path + "/available-features"}>
                                                                        <i className='fas fa-check-circle'></i>{getValidLabels(pageText, 'availableFeaturesTopMenuTxt')}
                                                                    </Link>
                                                                </div>
                                                            </React.Fragment>
                                                        }

                                                        <div className='option-list' data-tip={"User Session Report"} onClick={() => { accumulation(pageNameAcc, getPageEventName("workspaceTopMenu", "userSessionReportClicked"), auth.activeCompany, auth.loginId, props.grpcClient) }}>
                                                            <Link className="" to={path + "/usersessionreport"}>
                                                                <i className='fa fa-desktop'></i>{getValidTooltip(pageText, 'userSessionReportMenuTxt')}
                                                            </Link>
                                                        </div>
                                                        <div className='option-list' data-tip={getValidTooltip(pageText, 'logoutSelectTooltipTxt')}>
                                                            <div className="link" onClick={() => {
                                                                accumulation(pageNameAcc, getPageEventName("workspaceTopMenu", "logoutClicked"), auth.activeCompany, auth.loginId, props.grpcClient)
                                                                logOutApi()
                                                            }}>
                                                                <i className='fa fa-sign-out-alt'></i>{getValidLabels(pageText, 'logoutTopMenuTxt')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment> : <div className='right-menu'>
                                                <div className='toggle-right-menu'>
                                                    <div className='button' onClick={() => {
                                                        setToggleRightMenu(current => !current)
                                                    }}>
                                                        <i className="fas fa-ellipsis-v"></i>
                                                    </div>
                                                </div>
                                                {
                                                    toggleRightMenu && <div className='right-menu-content'>
                                                        <div className='parent-box'>
                                                            <div className='title'>Status</div>
                                                            <div className='options'>
                                                                {
                                                                    statusTypes && statusTypes.map((data, i) => {
                                                                        return (<div key={i} className='option-list status_list' onClick={() => {
                                                                            let statusupdate = data.value
                                                                            let getStatus = statusupdate
                                                                            setUserStatus(data.value)
                                                                            updateActiveStatus(getStatus)
                                                                        }}>
                                                                            <div className='liststylesA'>
                                                                                <div className={`status_roundA ${data.value === "steppedAway" ? "orangRounA" : data.value === "notAvailable" || data.value === "notOnline" ? "orangRoundC" : ""}`}>
                                                                                </div>
                                                                            </div>
                                                                            <div className='liststylesB'>
                                                                                {data.key}
                                                                            </div>
                                                                            <div className='liststylesC'>
                                                                                {(data.value == userStatus || data.value_2 == userStatus) &&
                                                                                    <i className="fa fa-arrow-circle-left" aria-hidden="true" style={{ color: "#000" }}></i>
                                                                                }
                                                                            </div>
                                                                        </div>)
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='parent-box'>
                                                            <div className='title'>Users</div>
                                                            <div className='options'>
                                                                <div className='option-list' data-tip={getValidTooltip(pageText, 'profileSelectTooltipTxt')}>
                                                                    <Link className="" to={path + "/myprofile"}>
                                                                        <i className='fa fa-user'></i>{getValidLabels(pageText, 'myProfileTopMenuTxt')}
                                                                    </Link>
                                                                </div>
                                                                <div className='option-list' data-tip={getValidTooltip(pageText, 'preferencesSelectTooltipTxt')}>
                                                                    <Link className="" to={path + "/preferences"}>
                                                                        <i className='fa fa-cog'></i>{getValidLabels(pageText, 'preferencesTopMenuTxt')}
                                                                    </Link>
                                                                </div>
                                                                {
                                                                    auth.activeCompany && <div className='option-list' data-tip={"Hidden Channels list"}>
                                                                        <Link className="" to={path + "/hiddenchannels"}>
                                                                            <i className='fas fa-comment-slash	'></i>{getValidLabels(pageText, 'hiddenChannelsTopMenuTxt')}
                                                                        </Link>
                                                                    </div>
                                                                }

                                                                <div className='option-list' data-tip={"User Session Report"}>
                                                                    <Link className="" to={path + "/usersessionreport"}>
                                                                        <i className='fa fa-desktop'></i>{"User Session Report"}
                                                                    </Link>
                                                                </div>
                                                                <div className='option-list' data-tip={getValidTooltip(pageText, 'logoutSelectTooltipTxt')}>
                                                                    <div className="link" onClick={() => {
                                                                        logOutApi()
                                                                    }}>
                                                                        <i className='fa fa-sign-out-alt'></i>{getValidLabels(pageText, 'logoutTopMenuTxt')}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        }

                                    </div>
                                </div>
                            }
                        </div>
                    }
                </React.Fragment>
            }
            <div className="content" style={{ height: ((fullView) ? "100vh" : null) }}>
                {
                    (!fullView && menuOpen) && <React.Fragment>
                        <ReactTooltip place="bottom" type="dark" effect="float" html={true} />
                        <div className="left-menu pt-2">
                            <WorkspaceMenu
                                companyList={companyList}
                                activeCompanyId={activeCompanyId}
                                setActiveCompanyList={setActiveCompanyList}
                                activeUserType={activeUserType}
                                systemLevelRole={systemLevelRole}
                                unreadMsgList={unreadMsgList}
                                pageText={pageText}
                            />
                            <div className='users-icon'>
                                <div className='u-icon-parent'>
                                    <div className={`u-icon theme ${auth.theme}`} onClick={() => toggleTheme()}>
                                        <i className={`fas fa-${auth.theme === "default" ? "moon" : "sun"}`} data-tip={getValidTooltip(pageText, 'toggleThemeIconTxt')} />
                                    </div>
                                </div>
                                {
                                    (activeCompanyId && checkActiveAdmin(activeUserType, systemLevelRole)) && <div className='u-icon-parent'>
                                        {location.pathname === path + "/company" && <div className='line-active' />}
                                        <div className='u-icon'>
                                            <Link className="" to={path + "/company"}>
                                                <i className='fa fa-building' data-tip={getValidTooltip(pageText, 'orgIconTxt')} />
                                            </Link>
                                        </div>
                                    </div>
                                }

                                <div className='u-icon-parent'>
                                    {location.pathname === path + "/inbox" && <div className='line-active' />}
                                    <div className='u-icon inbox'>
                                        {msgBoxUnreadCount > 0 && <span className='message-count-mail-box'>
                                            {msgBoxUnreadCount > 99 ? "99+" : msgBoxUnreadCount}
                                        </span>}
                                        <Link className="" to={path + "/inbox"}>
                                            <i className='fa fa-inbox' data-tip={getValidTooltip(pageText, 'inboxIconTxt')} />
                                        </Link>
                                    </div>
                                </div>

                                {
                                    activeCompanyId && <div className='u-icon-parent'>
                                        {location.pathname === path + "/feedback" && <div className='line-active' />}
                                        <div className='u-icon feedback'>
                                            <Link className="" to={path + "/feedback"}>
                                                <i className='fa fa-user-edit' data-tip={getValidTooltip(pageText, 'commsFeedbackTopMenuTxt')} />
                                            </Link>
                                        </div>
                                    </div>
                                }
                                <div className='u-icon-parent'>
                                    {location.pathname === path + "/switch-view" && <div className='line-active' />}
                                    <div className='u-icon switch-view'>
                                        <Link className="" to={path + "/switch-view"}>
                                            <i className='fa fa-desktop' data-tip={getValidTooltip(pageText, 'switchViewTxt')} />
                                        </Link>
                                    </div>
                                </div>
                                <div className='u-icon-parent'>
                                    {location.pathname === path + "/switch-account" && <div className='line-active' />}
                                    <div className='u-icon switch-account'>
                                        <Link className="" to={path + "/switch-account"}>
                                            <i className='fa fa-box' data-tip={getValidTooltip(pageText, 'switchAccountSideMenuTxt')} />
                                        </Link>
                                    </div>
                                </div>
                                <div className='u-icon-parent'>
                                    {location.pathname === path + "/faq" && <div className='line-active' />}
                                    <div className='u-icon help'>
                                        <Link className="" to={path + "/faq"}>
                                            <i className='fa fa-question-circle' data-tip={getValidTooltip(pageText, 'fAQIconTxt')} />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            (activeCompanyId && sidemenuOpen) && <div className={`sidemenu ${callWindowStatus === "minimized" ? "minimized" : ""}`}>
                                <div className="menu-content-user">
                                    {/* <div>{getValidLabels(pageText, 'hiTxt')} <span>{firstName}</span>  {(activeCompanyId && loginId === activeCompanyOwner) && <span> {`(` + getValidLabels(pageText, 'adminText') + `)`} </span>}  {loginId !== activeCompanyOwner && <span> {`(${companyMemberType(activeUserType)})`}</span>}  </div> */}
                                    <div>{getValidLabels(pageText, 'hiTxt')} <span>{firstName}</span></div>
                                    <div className='logged-email'>{loggedEmail}</div>
                                </div> <div className="menu-content">
                                    <ul className="menu">
                                        {getSideMenu()}
                                    </ul>
                                </div>
                            </div>
                        }
                    </React.Fragment>
                }

                {
                    (Object.keys(incomingCallData).length > 0 && incomingCallModal) && <IncomingCallModal
                        visible={incomingCallModal}
                        closeModal={() => this.setState({ incomingCallModal: false })}
                        heading={`Incoming Call`}
                        body={incomingCallModal && <div className="incoming-call">
                            <div className="profile">
                                <i className="fa fa-user" />
                            </div>
                            <div className="user-info">
                                <div className="name">{incomingCallData.message.fromName}</div>
                                <div className="message">is calling you</div>
                            </div>
                            <div className="call-answer">
                                <button className="btn btn-reject" onClick={() => callReject()}>
                                    <i className="fa fa-times" />
                                </button>
                                <button className="btn btn-answer" onClick={() => callAnswer()}>
                                    <i className="fa fa-phone" />
                                </button>
                            </div>
                        </div>
                        }
                    />
                }

                {
                    (updateModal && !callWindowData) && <Modal
                        visible={updateModal}
                        size="modal-md"
                        heading={"Confirmation"}
                        body={<React.Fragment>
                            <div>
                                We have recently completed an upgrade to the most current version. Please confirm your approval to refresh the website.
                            </div>
                            <button className="btn-md btn btn-primary text-center" style={{ display: "flex", margin: "auto" }} onClick={() => {
                                window.location.reload();
                            }}>Okay</button>
                        </React.Fragment>}


                    />
                }

                {
                    (providerModal) && <Modal
                        visible={providerModal}
                        size="modal-lg"
                        heading={"Provider Information"}
                        body={<React.Fragment>
                            <div className=''>
                                <ProviderUpdate
                                    pageType={"modal"}
                                    grpcClient={props.grpcClient}
                                    history={props.history}
                                    onCloseModal={() => {
                                        setProviderModal(false)
                                    }}
                                />
                            </div>
                        </React.Fragment>}


                    />
                }

                <div className={`users-apps-main ${callWindowStatus}`}>
                    <div className='users-main'>
                        {
                            searchDisplay && <div className='search-box-parent'>
                                <ChatSearch
                                    searchSubmit={searchSubmit}
                                    checkedChannelList={checkedChannelList}
                                    allChannelList={allChannelList}
                                    channelId={channelId}
                                    setSearchDisplay={setSearchDisplay}
                                    searchText={searchText}
                                    searchGrpcClient={props.searchGrpcClient}
                                    companyType={companyType}
                                    appsLayout={appsLayout}
                                    updateChannelUnReadCountIsEmpty={updateChannelUnReadCountIsEmpty}
                                    grpcClient={props.grpcClient}
                                    history={props.history}
                                    grpcPollingClient={props.grpcPollingClient}
                                    docsGrpcClient={props.docsGrpcClient}
                                    path={path}
                                    languagePreference={languagePreference}
                                    contextHelp={contextHelp}
                                    setContextHelp={setContextHelp}
                                />
                            </div>
                        }
                        {
                            (openedApps.length > 0 && activeToken) && <div className={`apps-bar ${appWindowStatus} ${activeWindow === "" ? "d-none" : ""}`}>

                                {
                                    openedApps.map((app, index) => {
                                        let { appsType, iframeUrl, isAppBuilder } = apps[app];

                                        if (isAppBuilder) {
                                            iframeUrl = iframeUrl + "/" + activeCompanyId + "/" + auth.activeUserType + "/" + apps[app].id + "/" + activeToken
                                        } else if (iframeUrl && iframeUrl.includes("/auth")) {
                                            iframeUrl = iframeUrl + "/" + activeToken
                                        }

                                        return <div key={index} className={`apps-window ${app === activeWindow ? "d-block" : "d-none"}`}>
                                            <div className='menu-bar'>
                                                <div data-tip={`Close Window`} className='icon w-close' onClick={() => {
                                                    closeApps(app)
                                                    setActiveWindow("")
                                                }}><i className='fa fa-times'></i></div>
                                                <div className='icon resize' onClick={() => {
                                                    if (appWindowStatus === "resized") {
                                                        setAppWindowStatus("")
                                                    } else {
                                                        setAppWindowStatus("resized")
                                                    }
                                                }}><i className="fas fa-expand-alt"></i></div>
                                                <div className='icon' onClick={() => {
                                                    setActiveWindow("")
                                                }}><i className='fa fa-minus'></i></div>
                                            </div>
                                            <div className="apps-inner-window">
                                                {
                                                    (appsType === "teqitTv" || appsType === "simulationGraph" || appsType === "docs" || appsType === "sheets" || appsType === "teqdraw" || appsType === "updateFeedback" || appsType === "AppsBuilder") ? getAppsView(apps[app]) : <React.Fragment>
                                                        {
                                                            (openedAppsLoader[app] !== undefined && openedAppsLoader[app].loading === true) && <div className='loader-apps'>
                                                                <div className="loading text-center">
                                                                    <div className="spinner-border text-primary" role="status">
                                                                        <span className="sr-only">Loading...</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        <iframe key={loadCount} allow="camera; microphone" onLoad={() => {
                                                            closeIframeLoader(app)
                                                        }} border={0} frameBorder={0} src={iframeUrl} style={{ height: '100%', width: '100%', background: '#fff' }} />
                                                    </React.Fragment>
                                                }
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        }

                        {
                            (pinTabInfo.type && !location.pathname.includes(pinTabInfo.type)) && <PinnedTab
                                pinTabInfo={pinTabInfo}
                                setPinTabInfo={setPinTabInfo}
                                setActiveDocsId={setActiveDocsId}
                                setActiveSheetId={setActiveSheetId}
                                setActiveDrawId={setActiveDrawId}
                                searchGrpcClient={props.searchGrpcClient}
                                path={path}
                                docsGrpcPollingClient={props.docsGrpcPollingClient}
                                docsGrpcClient={props.docsGrpcClient}
                                grpcClient={props.grpcClient}
                                history={props.history}
                                notesTeqitAiEnable={auth.controlFeature.notesTeqitAiEnable}
                                notesChannelUpdated={notesChannelUpdated}
                                setShareMessage={setShareMessage} />
                        }
                        <div className={`users-main-content `} >
                            <div className={'page-content'}>
                                {helpContextWindow && <div className={`${helpContextWindowSize ? helpContextWindowSize : "chVideo-window-minimized"}`} style={{ zIndex: 1 }}>
                                    <div className='menu-bar'>
                                        <div className='icon resize' onClick={() => {
                                            setHelpContextWindow(false)
                                            setHelpContextWindowSize("")
                                        }} style={{ backgroundColor: "red" }}><i className="fa fa-times mt-1" style={{ fontSize: 12 }}></i></div>
                                        <div className='icon resize' onClick={() => {
                                            if (helpContextWindowSize === "helpContextWindowResized") {
                                                setHelpContextWindowSize("")
                                            } else {
                                                setHelpContextWindowSize("helpContextWindowResized")
                                            }
                                        }}><i className="fas fa-expand-alt"></i></div>
                                        <div className='icon' onClick={() => {
                                            if (helpContextWindowSize === "helpContextWindowMinimized") {
                                                setHelpContextWindowSize("")
                                            } else {
                                                setHelpContextWindowSize("helpContextWindowMinimized")
                                            }
                                        }}><i className='fa fa-minus'></i></div>
                                    </div>
                                    <div className="call-inner-window">
                                        <video width="100%" controls>
                                            <source src={contexthelpJson[helpContextVidUrl]} />
                                        </video>
                                    </div>
                                </div>}

                                {/*GHT ROUTE */}
                                {/* <Route path={`${path}`}>
                                    <GHTRoute history={props.history} grpcClient={props.grpcClient} path={path}
                                        openApps={openApps}
                                        languagePreference={languagePreference}
                                        setContextHelp={setContextHelp}
                                        activeCompanyOwner={activeCompanyOwner}
                                        companyType={companyType}
                                    />
                                </Route> */}
                                {/*GHT ROUTE */}

                                <SwitchAccountAlert workspaceType={subRoute} />
                                <Suspense fallback={<Loader />}>
                                    <Switch>
                                        <Route exact path={`${path}/switch-view`}>
                                            <SwitchView history={props.history} grpcClient={props.grpcClient} languagePreference={languagePreference}
                                                setContextHelp={setContextHelp}
                                            />
                                        </Route>
                                        <Route exact path={`${path}/switch-account/:joinedOrgId?`}>
                                            <SwitchAccount history={props.history} grpcClient={props.grpcClient} notifyCompanyCreated={notifyCompanyCreated} setActiveCompanyId={setActiveCompanyId} setActiveCompanyOwner={setActiveCompanyOwner} setFeedId={setFeedId} path={path} languagePreference={languagePreference} refreshCompanyDetails={refreshCompanyDetails} joinOrgRefresh={joinOrgRefresh} newMsgArrived={newMsgArrived} />
                                        </Route>
                                        <Route exact path={`${path}/company`}>
                                            <Company history={props.history} grpcClient={props.grpcClient} setActiveCompanyColor={setActiveCompanyColor} notifyCompanyCreated={notifyCompanyCreated} setActiveCompanyName={setActiveCompanyName} setActiveCompanyId={setActiveCompanyId} setActiveCompanyOwner={setActiveCompanyOwner} languagePreference={languagePreference} refreshCompanyDetails={refreshCompanyDetails}
                                                contextHelp={contextHelp} setContextHelp={setContextHelp}
                                            />
                                        </Route>
                                        <Route exact path={`${path}/company-setup`}>
                                            <CompanySetup searchGrpcClient={props.searchGrpcClient} companyType={companyType} history={props.history} grpcClient={props.grpcClient} notifyCompanyCreated={notifyCompanyCreated} setActiveCompanyId={setActiveCompanyId} setActiveCompanyOwner={setActiveCompanyOwner} setFeedId={setFeedId} path={path} />
                                        </Route>
                                        <Route exact path={`${path}/feedback`}>
                                            <Feedback companyList={companyList} grpcClient={props.grpcClient} history={props.history} activeCompanyOwner={activeCompanyOwner} path={path} languagePreference={languagePreference} companyType={companyType} />
                                        </Route>
                                        <Route exact path={`${path}/feedback-add`}>
                                            <FeedbackAdd grpcClient={props.grpcClient} history={props.history} path={path} languagePreference={languagePreference} />
                                        </Route>
                                        <Route exact path={`${path}/feedback-edit/:editId`}>
                                            <FeedbackEdit grpcClient={props.grpcClient} history={props.history} path={path} languagePreference={languagePreference} />
                                        </Route>
                                        <Route exact path={`${path}/myprofile`}>
                                            <MyProfile path={path} setLanguagePreference={setLanguagePreference} setActiveUserCompanyColor={setActiveUserCompanyColor} setFirstName={setFirstName} grpcClient={props.grpcClient} history={props.history} vsCodeMappingData={vsCodeMappingData} vsCodeReadData={() => { getVSCodeMappingDetails() }} languagePreferencemyprofile={languagePreference} />
                                        </Route>
                                        <Route exact path={`${path}/org-request`}>
                                            <OrgRequest path={path} setLanguagePreference={setLanguagePreference} setActiveUserCompanyColor={setActiveUserCompanyColor} setFirstName={setFirstName} grpcClient={props.grpcClient} history={props.history} vsCodeMappingData={vsCodeMappingData} vsCodeReadData={() => { getVSCodeMappingDetails() }} languagePreferencemyprofile={languagePreference} />
                                        </Route>
                                        <Route exact path={`${path}/preferences`}>
                                            <Preferences grpcClient={props.grpcClient} history={props.history} languagePreference={languagePreference} />
                                        </Route>
                                        <Route exact path={`${path}/launchpad`}>
                                            <Launchpad logOutApi={logOutApi} feedId={feedId} calendarChannel={calendarChannel} grpcClient={props.grpcClient} history={props.history} activeCompanyOwner={activeCompanyOwner} path={path} languagePreference={languagePreference}
                                                pollChannelUnreadCount={pollChannelUnreadCount}
                                                newChannelArrived={newChannelArrived}
                                                companyType={companyType}
                                                setupChannelList={setupChannelList}
                                            />
                                        </Route>

                                        <Route exact path={`${path}/faq`}>
                                            <HelpFaq grpcClient={props.grpcClient} history={props.history} companyType={companyType}
                                                setHelpContextWindow={setHelpContextWindow}
                                                setHelpContextVidUrl={setHelpContextVidUrl}
                                                languagePreference={languagePreference}
                                            />
                                        </Route>


                                        <Route exact path={`${path}/inbox`}>
                                            <InboxList path={path} openApps={openApps} companyList={companyList} inboxUpdated={inboxUpdated} grpcClient={props.grpcClient} history={props.history} companyType={companyType} languagePreference={languagePreference} contextHelp={contextHelp} setContextHelp={setContextHelp} msgBoxUnreadCount={msgBoxUnreadCount} />
                                        </Route>
                                        <Route exact path={`${path}/docs-summarizer`}>
                                            <DocumentSummarizer grpcClient={props.grpcClient} history={props.history} companyType={companyType} languagePreference={languagePreference} contextHelp={contextHelp} setContextHelp={setContextHelp}
                                                aiDocsSuccessUpdated={aiDocsSuccessUpdated}
                                                aiDocsFileIndexed={aiDocsFileIndexed}
                                                docsSummarizerDataResponse={docsSummarizerDataResponse}
                                            />
                                        </Route>

                                        <Route exact path={`${path}/usersessionreport`}>
                                            <UserSessionReport grpcClient={props.grpcClient} history={props.history} languagePreference={languagePreference} contextHelp={contextHelp} setContextHelp={setContextHelp} />
                                        </Route>
                                        {auth.controlFeature.donationEnable &&
                                            <Route exact path={`${path}/donationform/:donationId?`}>
                                                <DonationForm grpcClient={props.grpcClient} history={props.history} languagePreference={languagePreference} contextHelp={contextHelp} setContextHelp={setContextHelp} path={path} />
                                            </Route>
                                        }
                                        {auth.controlFeature.donationInventoryEnable &&
                                            <Route exact path={`${path}/donation-inventory/:donationInventoryId?`}>
                                                <DonationInventory grpcClient={props.grpcClient} history={props.history} languagePreference={languagePreference} path={path} />
                                            </Route>
                                        }

                                        {auth.mailNotificationForChat && <Route exact path={`${path}/message-notification-setup`}>
                                            <MessageNotificationSetup grpcClient={props.grpcClient} history={props.history} languagePreference={languagePreference} path={path} />
                                        </Route>}

                                        {
                                            (activeCompanyId && accessVerify) ? <>
                                                {
                                                    getRoute()
                                                }


                                                <Route exact path={`${path}/docs`}>
                                                    <Docs setActiveDocsId={setActiveDocsId} searchGrpcClient={props.searchGrpcClient} path={path} docsGrpcPollingClient={props.docsGrpcPollingClient} docsGrpcClient={props.docsGrpcClient} grpcClient={props.grpcClient} history={props.history} companyType={companyType} languagePreference={languagePreference}
                                                        contextHelp={contextHelp} setContextHelp={setContextHelp}
                                                    />
                                                </Route>
                                                <Route exact path={`${path}/docs/:docsId/:docsOrgId`}>
                                                    <DocsWrite pinTabInfo={pinTabInfo} setPinTabInfo={setPinTabInfo} setActiveDocsId={setActiveDocsId} searchGrpcClient={props.searchGrpcClient} path={path} docsGrpcPollingClient={props.docsGrpcPollingClient} docsGrpcClient={props.docsGrpcClient} grpcClient={props.grpcClient} history={props.history} companyType={companyType}
                                                        docsNotificationMsg={docsNotificationMsg}
                                                        setDocsNotificationMsg={setDocsNotificationMsg}
                                                    />
                                                </Route>
                                                <Route exact path={`${path}/sheets`}>
                                                    <Sheets pinTabInfo={pinTabInfo} setPinTabInfo={setPinTabInfo} setActiveSheetId={setActiveSheetId} searchGrpcClient={props.searchGrpcClient} path={path} docsGrpcPollingClient={props.docsGrpcPollingClient} docsGrpcClient={props.docsGrpcClient} grpcClient={props.grpcClient} history={props.history} companyType={companyType} languagePreference={languagePreference}
                                                        contextHelp={contextHelp} setContextHelp={setContextHelp}
                                                    />
                                                </Route>
                                                <Route exact path={`${path}/sheets/:teqSheetId/:teqOrgId`}>
                                                    <SheetsWrite pinTabInfo={pinTabInfo} setPinTabInfo={setPinTabInfo} setActiveSheetId={setActiveSheetId} searchGrpcClient={props.searchGrpcClient} path={path} docsGrpcPollingClient={props.docsGrpcPollingClient} docsGrpcClient={props.docsGrpcClient} grpcClient={props.grpcClient} history={props.history} companyType={companyType} />
                                                </Route>
                                                <Route exact path={`${path}/timeentry`}>
                                                    {
                                                        auth.controlFeature.communityOrganization ? <CommunityServiceWorkspace /> : <Workspace dayEntryUpdate={dayEntryUpdate} dayEntryDateUpdate={dayEntryDateUpdate} searchGrpcClient={props.searchGrpcClient} reportGrpcClient={props.reportGrpcClient} contextHelp={contextHelp} setContextHelp={setContextHelp} companyType={companyType} clientUpdated={clientUpdated} taskUpdated={taskUpdated} projectUpdated={projectUpdated} activeCompanyId={activeCompanyId} grpcClient={props.grpcClient} history={props.history} languagePreference={languagePreference} />
                                                    }

                                                </Route>



                                                <Route exact path={`${path}/members`}>
                                                    <Members
                                                        memberJoinUpdated={memberJoinUpdated}
                                                        grpcClient={props.grpcClient}
                                                        history={props.history}
                                                        companyType={companyType}
                                                        languagePreference={languagePreference}
                                                        contextHelp={contextHelp}
                                                        setContextHelp={setContextHelp}
                                                        userLastAccessEnable={auth.controlFeature.userLastAccessEnable}
                                                        searchGrpcClient={props.searchGrpcClient}
                                                    />
                                                </Route>


                                                <Route exact path={`${path}/chat/:channelId/:feed?`}>
                                                    <Chat
                                                        pollChannel={pollChannel}
                                                        pollChannelUnreadCount={pollChannelUnreadCount}
                                                        setShareMessage={setShareMessage}
                                                        chatEnable={true}
                                                        setCallWindowData={setCallWindowData}
                                                        activeWindow={((callWindowData && callWindowStatus !== "minimized") || (pinTabInfo.id && pinTabInfo.type)) ? "call-window" : activeWindow}
                                                        setChannelId={setChannelId}
                                                        searchGrpcClient={props.searchGrpcClient}
                                                        companyType={companyType}
                                                        appsLayout={appsLayout}
                                                        updateChannelUnReadCountIsEmpty={updateChannelUnReadCountIsEmpty}
                                                        grpcClient={props.grpcClient}
                                                        history={props.history}
                                                        grpcPollingClient={props.grpcPollingClient}
                                                        path={path}
                                                        languagePreference={languagePreference}
                                                        contextHelp={contextHelp}
                                                        setContextHelp={setContextHelp}
                                                        openApps={openApps}
                                                    />
                                                </Route>


                                                <Route exact path={`${path}/search-chat/:channelId/:feed?`}>
                                                    <ChatSearch searchGrpcClient={props.searchGrpcClient} companyType={companyType} appsLayout={appsLayout} updateChannelUnReadCountIsEmpty={updateChannelUnReadCountIsEmpty} grpcClient={props.grpcClient} history={props.history} grpcPollingClient={props.grpcPollingClient} path={path} languagePreference={languagePreference} contextHelp={contextHelp} setContextHelp={setContextHelp} />
                                                </Route>

                                                {
                                                    auth.controlFeature.orgFeedbackEnable && <>
                                                        <Route exact path={`${path}/org-feedback`}>
                                                            <OrgFeedback grpcClient={props.grpcClient} history={props.history} activeCompanyOwner={activeCompanyOwner} path={path} languagePreference={languagePreference} />
                                                        </Route>
                                                        <Route exact path={`${path}/org-feedback-add`}>
                                                            <OrgFeedbackAdd grpcClient={props.grpcClient} history={props.history} path={path} languagePreference={languagePreference} />
                                                        </Route>
                                                        <Route exact path={`${path}/org-feedback-edit/:editId`}>
                                                            <OrgFeedbackEdit grpcClient={props.grpcClient} history={props.history} path={path} languagePreference={languagePreference} contextHelp={contextHelp} setContextHelp={setContextHelp} />
                                                        </Route>
                                                        <Route exact path={`${path}/org-feedbacklist`}>
                                                            <FeedbackList grpcClient={props.grpcClient} history={props.history} path={path} languagePreference={languagePreference} contextHelp={contextHelp} setContextHelp={setContextHelp} />
                                                        </Route>
                                                        <Route exact path={`${path}/org-feedback-based-status`}>
                                                            <OrgFeedbackStatus grpcClient={props.grpcClient} history={props.history} languagePreference={languagePreference} />
                                                        </Route>
                                                    </>
                                                }
                                                <Route exact path={`${path}/calendar`}>
                                                    <Calendar searchGrpcClient={props.searchGrpcClient} calendarUpdated={calendarUpdated} grpcClient={props.grpcClient} history={props.history} path={path} companyType={companyType} languagePreference={languagePreference} contextHelp={contextHelp} setContextHelp={setContextHelp} activeUserType={activeUserType}
                                                        systemLevelRole={systemLevelRole}
                                                        activeCompanyId={activeCompanyId}
                                                        leaveRequest={leaveRequest}
                                                    />
                                                </Route>
                                                <Route exact path={`${path}/calendar-message/:channelId`}>
                                                    <CalendarMessage
                                                        setChannelId={setChannelId}
                                                        pollChannel={pollChannel}
                                                        grpcPollingClient={props.grpcPollingClient}
                                                        grpcClient={props.grpcClient}
                                                        history={props.history}
                                                        path={path}
                                                        languagePreference={languagePreference}
                                                        contextHelp={contextHelp}
                                                        setContextHelp={setContextHelp} />
                                                </Route>

                                                <Route exact path={`${path}/apps/:project`}>
                                                    <AppsView grpcClient={props.grpcClient} history={props.history} openApps={openApps} />
                                                </Route>

                                                <Route exact path={`${path}/call/:activeChannelId/:roomId/:messageId`}>
                                                    <Call pollCall={pollCall} grpcPollingClient={props.grpcPollingClient} grpcClient={props.grpcClient} history={props.history} path={path} />
                                                </Route>
                                                <Route exact path={`${path}/incoming-call/:activeChannelId/:roomId/:messageId`}>
                                                    <IncomingCall grpcClient={props.grpcClient} history={props.history} />
                                                </Route>
                                                <Route exact path={`${path}/join-call/:activeChannelId/:roomId/:messageId`}>
                                                    <JoinCall pollCall={pollCall} grpcPollingClient={props.grpcPollingClient} grpcClient={props.grpcClient} history={props.history} path={path} />
                                                </Route>
                                                <Route exact path={`${path}/join-meeting-call/:meetingId`}>
                                                    <JoinMeetingCall grpcClient={props.grpcClient} history={props.history} path={path} />
                                                </Route>
                                                <Route exact path={`${path}/meeting-thanks`}>
                                                    <MeetingThanks grpcClient={props.grpcClient} history={props.history} path={path} />
                                                </Route>
                                                <Route exact path={`${path}/call-thanks/:channelId`}>
                                                    <CallThanks grpcClient={props.grpcClient} history={props.history} path={path} />
                                                </Route>
                                                <Route exact path={`${path}/call-ended/:channelId`}>
                                                    <CallEnded grpcClient={props.grpcClient} history={props.history} path={path} />
                                                </Route>
                                                <Route exact path={`${path}/board`}>
                                                    <BoardMenu appsLayout={true} activeCompanyId={activeCompanyId} newBoardArrived={newBoardArrived} grpcPollingClient={props.grpcPollingClient} grpcClient={props.grpcClient} pageText={boardMenuText} taskUpdated={taskUpdated} teamUpdated={teamUpdated} />
                                                </Route>
                                                <Route exact path={`${path}/chat`}>
                                                    <ChatMenu
                                                        pollUser={pollUser}
                                                        pollChannelUnreadCount={pollChannelUnreadCount}
                                                        setAllChannelUnreadCount={setAllChannelUnreadCount}
                                                        shareMessage={shareMessage}
                                                        setShareMessage={setShareMessage}
                                                        searchGrpcClient={props.searchGrpcClient}
                                                        appsLayout={true}
                                                        activeCompanyId={activeCompanyId}
                                                        newChannelArrived={newChannelArrived}
                                                        notifyNewChannelArrived={notifyNewChannelArrived}
                                                        setCalendarUnreadMessageCount={setCalendarUnreadMessageCount}
                                                        messageCountTrigger={messageCountTrigger}
                                                        grpcPollingClient={props.grpcPollingClient}
                                                        grpcClient={props.grpcClient}
                                                        path={path}
                                                        companyType={companyType}
                                                        pageText={chatMenuText}
                                                        languagePreference={languagePreference}
                                                        newMsgArrived={newMsgArrived}
                                                        setRequestReceivingOpenMRSChannelUnreadCount={setOpenMRSChannelUnreadMessageCount}
                                                    />
                                                </Route>
                                                {auth.controlFeature.qatEnable && <>
                                                    <Route exact path={`${path}/qat`}>
                                                        <Qat searchGrpcClient={props.searchGrpcClient} grpcClient={props.grpcClient} history={props.history} companyType={companyType} path={path} languagePreference={languagePreference} contextHelp={contextHelp} setContextHelp={setContextHelp} />
                                                    </Route>

                                                    <Route exact path={`${path}/createqat`}>
                                                        <CreateQat searchGrpcClient={props.searchGrpcClient} clientUpdated={clientUpdated} projectUpdated={projectUpdated} grpcClient={props.grpcClient} history={props.history} companyType={companyType} path={path} languagePreference={languagePreference} />
                                                    </Route>
                                                    <Route exact path={`${path}/edit-qat/:editId`}>
                                                        <EditQat searchGrpcClient={props.searchGrpcClient} grpcClient={props.grpcClient} history={props.history} companyType={companyType} path={path} languagePreference={languagePreference} />
                                                    </Route>

                                                    <Route exact path={`${path}/qat-based-status`}>
                                                        <QatStatus grpcClient={props.grpcClient} history={props.history} path={path} languagePreference={languagePreference} />
                                                    </Route>
                                                </>

                                                }


                                                <Route exact path={`${path}/project-diagram/:designType/:clientId/:projectId`}>
                                                    <ProjectDiagram pinTabInfo={pinTabInfo} setPinTabInfo={setPinTabInfo} path={path} searchGrpcClient={props.searchGrpcClient} grpcClient={props.grpcClient} history={props.history} companyType={companyType} languagePreference={languagePreference} contextHelp={contextHelp} setContextHelp={setContextHelp} />
                                                </Route>
                                                <Route exact path={`${path}/project-diagram-list/:designType`}>
                                                    <ProjectDiagramList path={path} searchGrpcClient={props.searchGrpcClient} grpcClient={props.grpcClient} history={props.history} companyType={companyType} languagePreference={languagePreference} contextHelp={contextHelp} setContextHelp={setContextHelp} />
                                                </Route>
                                                <Route exact path={`${path}/project-diagram-ontology/:designType/:clientId/:projectId`}>
                                                    <ProjectDiagramOntology pinTabInfo={pinTabInfo} setPinTabInfo={setPinTabInfo} path={path} searchGrpcClient={props.searchGrpcClient} grpcClient={props.grpcClient} history={props.history} companyType={companyType} languagePreference={languagePreference} contextHelp={contextHelp} setContextHelp={setContextHelp} />
                                                </Route>
                                                <Route exact path={`${path}/cloudproviderusermapping`}>
                                                    <CloudProviderUserMapping grpcClient={props.grpcClient} history={props.history} languagePreference={languagePreference} contextHelp={contextHelp} setContextHelp={setContextHelp} />
                                                </Route>

                                                <Route exact path={`${path}/analytics`}>
                                                    <Analytics reportGrpcClient={props.reportGrpcClient} grpcClient={props.grpcClient} history={props.history} companyType={companyType} languagePreference={languagePreference} contextHelp={contextHelp} setContextHelp={setContextHelp} />
                                                </Route>


                                                <Route exact path={`${path}/notes`}>
                                                    <Notes
                                                        pinTabInfo={pinTabInfo}
                                                        setPinTabInfo={setPinTabInfo}
                                                        notesTeqitAiEnable={auth.controlFeature.notesTeqitAiEnable}
                                                        notesChannelUpdated={notesChannelUpdated}
                                                        grpcClient={props.grpcClient}
                                                        history={props.history}
                                                        companyType={companyType}
                                                        languagePreference={languagePreference}
                                                        contextHelp={contextHelp}
                                                        setContextHelp={setContextHelp}
                                                        setShareMessage={setShareMessage}
                                                        path={path}
                                                    />
                                                </Route>
                                                <Route exact path={`${path}/hiddenchannels`}>
                                                    <HiddenChannels grpcClient={props.grpcClient} history={props.history} languagePreference={languagePreference}
                                                        hideUnhideChannels={hideUnhideChannels}
                                                        contextHelp={contextHelp} setContextHelp={setContextHelp}
                                                    />
                                                </Route>
                                                <Route exact path={`${path}/teqpitch`}>
                                                    <TeqPitch setActivePitchId={setActivePitchId} searchGrpcClient={props.searchGrpcClient} path={path} docsGrpcClient={props.docsGrpcClient} grpcClient={props.grpcClient} history={props.history} languagePreference={languagePreference} />
                                                </Route>
                                                <Route exact path={`${path}/teqpitch/:teqPitchId`}>
                                                    <TeqPitchWrite setActivePitchId={setActivePitchId} searchGrpcClient={props.searchGrpcClient} path={path} docsGrpcClient={props.docsGrpcClient} docsGrpcPollingClient={props.docsGrpcPollingClient} grpcClient={props.grpcClient} history={props.history} languagePreference={languagePreference} />
                                                </Route>
                                                <Route exact path={`${path}/teqdraw/:teqDrawId/:teqDrawOrgId`}>
                                                    <TeqDrawWrite pinTabInfo={pinTabInfo} setPinTabInfo={setPinTabInfo} setActiveDrawId={setActiveDrawId} searchGrpcClient={props.searchGrpcClient} path={path} docsGrpcClient={props.docsGrpcClient} docsGrpcPollingClient={props.docsGrpcPollingClient} grpcClient={props.grpcClient} history={props.history} languagePreference={languagePreference} />
                                                </Route>
                                                <Route exact path={`${path}/teqdraw`}>
                                                    <TeqDraw setActiveDrawId={setActiveDrawId} searchGrpcClient={props.searchGrpcClient} path={path} docsGrpcClient={props.docsGrpcClient} grpcClient={props.grpcClient} history={props.history} languagePreference={languagePreference}
                                                        contextHelp={contextHelp} setContextHelp={setContextHelp}
                                                    />
                                                </Route>
                                                <Route exact path={`${path}/teqtock-guest`}>
                                                    <Guest grpcClient={props.grpcClient} history={props.history} languagePreference={languagePreference}
                                                        contextHelp={contextHelp} setContextHelp={setContextHelp}
                                                    />
                                                </Route>
                                                <Route exact path={`${path}/teqtock-event`}>
                                                    <Event path={path} grpcClient={props.grpcClient} searchGrpcClient={props.searchGrpcClient} history={props.history} languagePreference={languagePreference} contextHelp={contextHelp} setContextHelp={setContextHelp} />
                                                </Route>
                                                <Route exact path={`${path}/mail-config`}>
                                                    <MailConfig grpcClient={props.grpcClient} history={props.history} languagePreference={languagePreference} />
                                                </Route>
                                                <Route exact path={`${path}/join-teqtock-call/:channelId/:eventId`}>
                                                    <JoinCalendlyCall setChannelId={setChannelId} pollChannel={pollChannel} setCallWindowData={setCallWindowData} grpcPollingClient={props.grpcPollingClient} grpcClient={props.grpcClient} history={props.history} languagePreference={languagePreference} />
                                                </Route>
                                                <Route exact path={`${path}/holiday`}>
                                                    <HolidayDate grpcClient={props.grpcClient} history={props.history} setContextHelp={setContextHelp} contextHelp={contextHelp}
                                                        languagePreference={languagePreference}
                                                    />
                                                </Route>
                                                <Route exact path={`${path}/chat-models`}>
                                                    <ChatModels grpcClient={props.grpcClient} history={props.history} setContextHelp={setContextHelp} contextHelp={contextHelp} />
                                                </Route>
                                                <Route exact path={`${path}/tv-management`}>
                                                    <TvManagement path={path} workspaceType={subRoute} grpcClient={props.grpcClient} history={props.history} languagePreference={languagePreference}
                                                        setContextHelp={setContextHelp} contextHelp={contextHelp}
                                                    />
                                                </Route>
                                                <Route exact path={`${path}/teqit-tv-view/:id`}>
                                                    <TeqitTvView workspaceType={subRoute} grpcClient={props.grpcClient} history={props.history} languagePreference={languagePreference}
                                                        setContextHelp={setContextHelp} contextHelp={contextHelp}
                                                    />
                                                </Route>

                                                <Route exact path={`${path}/external-user-mapping`}>
                                                    <ExternalAppUserMapping grpcClient={props.grpcClient} history={props.history} />
                                                </Route>
                                                <Route exact path={`${path}/call-host-management`}>
                                                    <CallHostManagement grpcClient={props.grpcClient} history={props.history}
                                                        languagePreference={languagePreference}
                                                    />
                                                </Route>
                                                <Route exact path={`${path}/available-features`}>
                                                    <AvailableFeatures grpcClient={props.grpcClient} history={props.history} languagePreference={languagePreference} />
                                                </Route>
                                                {GlobalMsgCons.roleTypeProvider === activeUserType && <Route exact path={`${path}/provider-update`}>
                                                    <ProviderUpdate path={path} grpcClient={props.grpcClient} history={props.history} pageType="update" />
                                                </Route>}

                                                {
                                                    auth.controlFeature.appsEnable && <React.Fragment>
                                                        <Route exact path={`${path}/apps`}>
                                                            <Apps grpcClient={props.grpcClient} history={props.history} openApps={openApps} activeCompanyOwner={activeCompanyOwner} companyType={companyType} languagePreference={languagePreference} contextHelp={contextHelp} setContextHelp={setContextHelp} />
                                                        </Route>
                                                        <Route exact path={`${path}/apps-management`}>
                                                            <AppsManagement grpcClient={props.grpcClient} history={props.history} openApps={openApps} activeCompanyOwner={activeCompanyOwner} companyType={companyType} languagePreference={languagePreference} contextHelp={contextHelp} setContextHelp={setContextHelp} />
                                                        </Route>
                                                    </React.Fragment>
                                                }

                                                <Route exact path={`${path}/client`}>
                                                    <Client grpcClient={props.grpcClient} history={props.history} companyType={companyType} languagePreference={languagePreference} contextHelp={contextHelp} setContextHelp={setContextHelp} />
                                                </Route>

                                                <Route exact path={`${path}/projects`}>
                                                    <Project searchGrpcClient={props.searchGrpcClient} clientUpdated={clientUpdated} companyType={companyType} taskUpdated={taskUpdated} projectUpdated={projectUpdated} grpcClient={props.grpcClient} history={props.history} languagePreference={languagePreference}
                                                        contextHelp={contextHelp} setContextHelp={setContextHelp} />
                                                </Route>

                                            </> : <React.Fragment>
                                                {
                                                    accessVerifyLoader ? <Loader /> : <div className='no-access d-flex align-items-center justify-content-center h-100'>
                                                        No access rights were granted for this workspace. Please check your login details.
                                                    </div>
                                                }
                                            </React.Fragment>
                                        }
                                    </Switch>
                                </Suspense>
                            </div>
                            {
                                contextHelp !== "" && <div className='help-content'>
                                    <ContextHelp contextHelp={contextHelp} setContextHelp={setContextHelp}
                                        setHelpContextWindow={setHelpContextWindow}
                                        setHelpContextVidUrl={setHelpContextVidUrl}
                                        languagePreference={languagePreference}
                                    />
                                </div>
                            }

                            {docsNotificationMsg && <DocsNotification setDocsNotificationMsg={setDocsNotificationMsg} />}


                        </div>
                    </div>
                    {
                        (openedApps.length > 0 && activeWindow === "") && <div className={`apps-minimized-bar`}>
                            {
                                openedApps.map((app, index) => {
                                    let { administration, logo, title } = apps[app]
                                    let imageUrl = AppDefaultImage;
                                    if (logo) {
                                        let split = logo.split(".")
                                        imageUrl = MyConstant.keyList.apiURL + "vp?action=appsLogo&key=" + split[0] + "." + split[1] + "&id=appsLogo"
                                    }

                                    return <div key={index} className={`apps-minimized-window`} style={{ background: apps[app].color }} onClick={() => {
                                        openApps(app)
                                        ReactTooltip.hide()
                                    }} data-tip={title}>
                                        {
                                            administration ? <div className='administration-apps-logo'>{title[0]}</div> : <div className='title'>
                                                <img className='img-fluid' src={imageUrl} />
                                            </div>
                                        }
                                        <ReactTooltip place="bottom" type="dark" effect="float" html={true} />
                                        <div className='apps-close' onClick={(e) => {
                                            e.stopPropagation();
                                            closeApps(app)

                                        }}>
                                            <i className='fa fa-times'></i>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    }
                </div>
            </div>
            {
                screenRecord && <ScreenRecord
                    grpcClient={props.grpcClient}
                    history={props.history}
                    path={path}
                    languagePreference={languagePreference}
                    setScreenRecord={setScreenRecord}
                    workspaceType={subRoute}
                />
            }
            {
                activeCompanyId && <InboxFeedbackNotification
                    grpcClient={props.grpcClient}
                    history={props.history}
                />
            }
        </div>
    );
}
export default withRouter(CommonWorkspaceRoute);